#testimonial {
	padding: 100px 0;
	background: #f3f5f9;
}

.testi-slider {
	padding: 20px 15px 40px;
}


.testimonial-content {
	background: #fff;
	padding: 40px;
	box-shadow: 0px 2px 4px 0px rgba(0, 0, 29, 0.08);
	transition: all 0.3s ease-in-out;
	.content {
		position: relative;

		.quote {
			position: absolute;
			right: 0;
			top: -10px;
			z-index: 0;
		}

		p {
			position: relative;
			z-index: 1;
			font-size: 16px;
		}

	}

	.testi-author {
		display: flex;
		align-items: center;

		.testi-avatar {
			height: 65px;
			width: 65px;
			border-radius: 50%;
			overflow: hidden;
			border: 5px solid #f9f0fb;
		}

		.user-details {
			margin-left: 30px;

			.name {
				font-size: 16px;
				font-weight: 600;
				margin-top: 0;
			}

			.position {
				font-size: 16px;
				color: #8b8b99;
			}
		}
	}

	&:hover {
		box-shadow: 0px 20px 40px 0px rgba(0, 0, 29, 0.08);
	}
}

.ratings {
	margin: 0 0 15px;
	padding: 0;
	list-style: none;
	text-align: center;

	li {
		display: inline-block;
		margin-right: 5px;
		color: #fac50f;
	}
}

/* Testimonial Two */
#testimonial-two {
	background: #f9fbff;
	padding: 40px 0 80px;
}

.testimonial-two {
	.slider-nav {
		.slick-slide {
			padding: 20px 0;
			height: 110px;

			&.slick-current {

				.testi-avatar {
					height: 80px;
					width: 80px;
					transform: translate(-10px,-10px);
					opacity: 1;
				}
			}
		}
	}
}

.testimonial-content-two {
	box-shadow: 0px 20px 50px 0px rgba(1, 0, 57, 0.1);
	max-width: 810px;
	margin: 45px auto;
	padding: 30px 50px 40px;
	border-radius: 10px;

	.quote {
		margin-bottom: 20px;
		img {
			margin: 0 auto 20px;
		}
	}

	p {
		font-size: 15px;
	}

	.testi-author {
		.user-details {
			.name, .position {
				display: inline-block;
			}

			.name {
				font-size: 16px;
				font-weight: 600;
				color: #282835
			}

			.position {
				font-size: 14px;
				color: #b0b0bd;
				font-weight: 300;
				margin-left: 15px;
				position: relative;

				&:before {
					content: '/';
					position: absolute;
					left: -10px;
					top: 0;
				}
			}
		}
	}
}

.slider-nav {
	width: 350px;
	margin: 0 auto;


	.testi-avatar {
		height: 60px;
		width: 60px;
		border-radius: 50%;
		transition: all 0.3s ease-in-out;
		opacity: 0.6;
		cursor: pointer;

		img {
			border-radius: 50%;
			width: 100%;
		}
	}
}


.PrevArrow, .NextArrow {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	z-index: 6666;
	font-size: 30px;
	transition: all 0.3s ease-in-out;
	cursor: pointer;

	&:hover {
		color: $color__theme;
	}
}

.PrevArrow {
	left: 0;
}

.NextArrow {
	right: 0;
}


@media (min-width: 600px) and (max-width: 768px) {
	.testimonial-content {
		padding: 15px;

		.testi-author {
			.user-details {
				margin-left: 10px;

				.name {
					font-size: 14px;
					margin-bottom: 0px;
				}

				.position {
					font-size: 12px;
				}
			}
		}
	}


}

@media (max-width: 768px) {
	#testimonial-two {
		padding: 10px 0 30px;
	}

	#testimonial {
		padding: 50px 0;
	}
}


@media (max-width: 576px) {
	.slider-nav {
		width: 90%;


		.testi-avatar {
			height: 50px;
		}
	}

	.slick-arrow{
		display: none !important;
	}



	.testimonial-content {
		padding: 20px;

		.content {
			p {
				font-size: 14px;
			}
		}
	}
}

#faq {
	padding: 100px 0 70px;
	background: #fafcff;
}

#accordion {
	margin-top: 80px;
}

.card, .card-header {
	background: transparent;
	border: 0;
	padding: 10px 0;

}


.card {
	padding: 0;

	.card-header {
		border-bottom: 1px solid #d7e0ed;
		position: relative;

		.btn {
			color: #81818d;
			text-decoration: none;
			font-size: 18px;
			font-weight: 500;

			i {
				margin-right: 10px;
			}
		}


	}

	&:last-child {
		.card-header {
			border-bottom: transparent;
		}
	}

	&.active {
		.card-header {

			border-bottom: transparent;


			.btn {
				color: $color__theme;
			}
		}
	}
}

.card-body {
	padding: 10px 10px 10px 45px;

	p {
		margin: 0;
	}
}

.card-header > h5:before, .collapsed:before {
	display: block;
	position: absolute;
	font-size: 25px;
	top: 50%;
	right: 0;
	z-index: 9;
	font-family: 'ElegantIcons';
	transition: all 0.5s;
	transform: translateY(-50%);
	font-size: 16px;
}

.card-header > h5:before {
	content: "\4b";
	color: $color__theme;

}

.collapsed:before {
	content: "\4c";


}
.faq-thumb {

	img {
		position: relative;
		z-index: 11;
	}
}

@media (max-width: 992px) {
	#accordion {
		margin-top: 0;
	}
}

@media (max-width: 768px) {
	#accordion {
		margin-bottom: 30px;
	}

	#faq {
		padding: 50px 0 30px;
		background: #fafcff;
	}

}
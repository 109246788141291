#site-banner {
	height: 100vh;
	padding-top: 210px;
	background-size: cover;
	background-position: center center;
	position: relative;
	overflow: hidden;
	background-image: linear-gradient( 40deg, rgb(50,36,175) 0%, rgb(201,109,216) 100%);

	.bn-bg {
		img {
			position: absolute;
			top: 0;
			right: 0;
		}
	}
}



.banner-content {
	margin-top: 100px;
	// position: absolute;
	// left: 36%;
	// top: 50%;
	// transform: translate(-50%,-50%);

	.title {
		font-size: 	50px;
		font-weight: 800;
		color: #fff;
		line-height: 55px;
	}

	.sub-title {
		font-size: 16px;
		font-weight: 500;
		color: #fff;
		margin: 40px 0 50px;

	}

	.banner-btn {
		background-image: $button__bg;
		background-size: 200% auto;
		color: #fff;
		padding: 17px 24px;
		font-size: 12px;
		font-weight: 600;
		text-transform: uppercase;
		transition: all 0.3s ease-in-out;
		display: inline-block;
		border-radius: 2px;
		box-shadow: 0px 20px 30px 0px rgba(0, 0, 29, 0.14);

		&:hover {
			background-position: right center;
		}
	}

}

.banner-mobile {
	text-align: right;
	transform: translateY(160px);
	z-index: 11;
	position: relative;
	animation: float-bob 3s infinite;
	// position: absolute;
	// right: 50%;
	// bottom: 20px;
	// transform: translateX(160%);
	// z-index: 999;
	//box-shadow: 40px 69.282px 140px 0px rgba(0, 0, 29, 0.3);
}


.header-bg {
	margin: 0;
	padding: 0;
	list-style: none;

	li {
		position: absolute;
		z-index: 0;

		&:nth-child(1) {
			bottom: 10%;
			left: -40px;
		}

		&:nth-child(2) {
			bottom: 30%;
			left: -20%;
		}

		&:nth-child(3) {
			top: 50%;
			left: -20px;
		}

		&:nth-child(4) {
			top: 30%;
			left: -60px;
		}

		&:nth-child(5) {
			top: 10%;
			right: -100px;
		}
		&:nth-child(6) {
			top: 24%;
			right: -20px;
		}

		&:nth-child(7) {
			top: 60%;
			right: -70px;
		}
	}
}

.bottom-shape, .bottom-shape-two {
	position: absolute;
	left: 0;
	bottom: -2px;
	z-index: 1;
}

.bottom-shape-two {
	left: 208px;
	bottom: 20px;
}


/* Banner Two */
#banner-two {
	height: 100vh;
	background-image: linear-gradient( 40deg, rgb(71,69,228) 0%, rgb(24,207,245) 100%);
	overflow: hidden;
	padding-top: 100px;

	.banner-content {
		// left: 50%;
		// top: 50%;
		// transform: translate(-50%, -130%);

		.btitle-top {
			font-size: 16px;
			letter-spacing: 6px;
			color: #fff;
			text-transform: uppercase;
			font-weight: 500;
		}

		h1 {
			font-size: 44px;
			color: #fff;
			text-transform: uppercase;
			font-weight: 700;
		}

		p {
			font-size: 15px;
			color: #fff;
			font-weight: 500;
			margin-bottom: 30px;
		}

		.banner-btn-light {
			display: inline-block;
			background: #fff;
			padding: 19px 33px;
			border-radius: 30px;
			color: $color__theme;
			font-weight: 600;
			text-transform: uppercase;
			font-size: 12px;
			line-height: 12px;
			box-shadow: 0px 16px 30px 0px rgba(1, 0, 57, 0.14);

			i {
				margin-left: 3px;
				transition: all 0.3s ease-in-out;
			}

			&:hover {
				background: $color__theme;
				color: #fff;

				i {
					margin-left: 10px;
				}
			}
		}
	}

	.laptop {
		position: absolute;
		left: 50%;
		bottom: 90px;
		transform: translateX(-50%);
		z-index: 1;
	}

	.mobile {
		position: absolute;
		left: 50%;
		bottom: 30px;
		z-index: 9;
		transform: translateX(80%);
	}

}

.scene {
	position: absolute;
	bottom: -40px;

	&.bg-one {
		z-index: 2;
	}
}

.item-bg {
	z-index: 9999;
	left: -100px !important;
	//animation: wave 5.77778s 0.1s infinite linear;
	position: relative;
}




.item-bg, .item-bg-two {
	// position: absolute;
	left: 0;
	bottom: 0;
	opacity: 0.302;
}

.item-bg-two {
	left: -100px !important;
	bottom: -40px;
	top: auto !important;
}


/* Bob */
@-webkit-keyframes float-bob {
	0% {
		-webkit-transform: translateY(-20px);
		transform: translateY(-20px);
	}

	50% {
		-webkit-transform: translateY(-10px);
		transform: translateY(-10px);
	}

	100% {
		-webkit-transform: translateY(-20px);
		transform: translateY(-20px);
	}
}

@keyframes float-bob {
	0% {
		-webkit-transform: translateY(-20px);
		transform: translateY(-20px);
	}

	50% {
		-webkit-transform: translateY(-10px);
		transform: translateY(-10px);
	}

	100% {
		-webkit-transform: translateY(-20px);
		transform: translateY(-20px);
	}
}



@media (max-width: 1440px) {


	.banner-mobile {
		img {
			max-width: 300px;
			float: right;
		}

	}
}

@media (max-width: 1200px) {

	#banner-two {
		padding-top: 65px;
	}

	.banner-content {


		.title {
			font-size: 40px;
			line-height: 50px;
		}

		.banner-btn {
			padding: 14px 20px;
		}
	}

	// .banner-mobile {
	// 	max-width: 230px;
	// 	bottom: 200px;
	// 	transform: translateX(200%);
	// }

	#banner-two {
		.mobile {
			bottom: 50px;

			img {
				width: 180px;
			}
		}
	}
}

@media (max-width: 1024px) {
	#banner-two {
		.banner-content {
			h1 {
				font-size: 35px;
			}

			p {
				font-size: 14px;
			}
		}

		.mobile {
			transform: translateX(60%)
		}
	}

}

@media (min-width: 812px) and (max-width: 992px) {
	#banner-two {
		.banner-content {
			z-index: 999;
			position: relative;
			margin: 0;

			p {
				display: none;
			}
		}
	}
}


@media (max-width: 992px) {

	#site-banner {
		padding-top: 60px;

		.col-md-7, .col-md-5 {
			position: static;
		}

		.banner-content  {
			margin-top: 0;
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			width: 60%;

			.title {
				line-height: 30px;
			}
		}
	}

	.banner-mobile {
		max-width: 200px;
		position: absolute;
		top: 25%;
		right: 50px;
		transform: translateY(-50%);

		img {
			width: 100%;
			float: right;
		}
	}

	.banner-content {
		// left: 35%;

		.title {
			font-size: 30px;
			line-height: 40px;
		}

		.sub-title {
			font-size: 14px;
			margin: 15px 0 30px;

			br {
				display: none;
			}
		}

		.banner-btn {
			padding: 14px 20px;
		}
	}

	#banner-two {
		.banner-content {

			.btitle-top {
				font-size: 14px;
				letter-spacing: 2px;
			}

			h1 {
				font-size: 28px;
			}

			// p {
			// 	display: none;
			// }

			.banner-btn-light {
				padding: 15px 28px;
			}
		}

		.laptop {
			bottom: 0;
			width: 350px;
		}

		.mobile {
			width: 140px;
			bottom: 0;
		}
	}
}



@media (min-width: 576px) and (max-width: 767px) {

	#site-banner {
		padding-top: 0;
	}

	#site-banner {

		.banner-content {
			text-align: center;

			.title {
				font-size: 35px;
				line-height: 45px;
				margin: 0;
			}

			.sub-title {
				margin: 15px 0 10px;
			}

			.banner-btn {
				padding: 12px 20px;
				margin-top: 20px;
				font-size: 12px;
			}
		}
	}

	.banner-mobile {
		display: none;
	}

	#banner-two {
		.banner-content {

			.btitle-top {
				font-size: 14px;
			}

			h1 {
				font-size: 22px;
			}

			.banner-btn-light {
				padding: 15px 22px;
			}
		}

		.laptop {
			bottom: 20px;
			max-width: 300px;
		}

		.mobile {
			bottom: 0;

			img {
				width: 120px;
			}
		}
	}
}

@media (max-width:768px) {

	#banner-two {
		.banner-content {
			top: 50%;
			transform: translateY(-40%);
			position: absolute;
			left: 0;
			width: 100%;
			padding: 0 10px;
			margin-top: 0;
			z-index: 1111;
		}

		.laptop, .mobile {
			display: none;
		}
	}

	#site-banner {
		.col-md-7 {
			position: static;
		}

		.banner-content {
			position: absolute;
			left: 0;
			top: 50%;
			width: 100%;
			padding: 0 10px;
			transform: translateY(-50%);
			text-align: center;
			margin-top: 0;
		}

		.banner-mobile {
			display: none;
		}
	}

}

@media (max-width: 576px) {

	#site-banner {
		padding-top: 0;

		.col-md-7 {
			position: static;
		}


		.banner-content {


			.btitle-top {
				font-size: 12px;
				letter-spacing: 2px;
			}

			.title {
				font-size: 22px;
				line-height: 32px;
				margin: 0;
			}

			.sub-title {
				margin-bottom: 20px;
				max-width: 300px;
				margin: 10px auto 20px;
				font-size: 12px;
				line-height: 20px;

				br {
					display: none;
				}

			}

			.banner-btn {
				padding: 12px 20px;
				margin-top: 20px;
				font-size: 12px;
				margin-top: 0;
			}
		}

	}

	.banner-mobile {
		display: none;
	}

	#banner-two {

		.banner-content {

			.btitle-top {
				font-size: 12px;
				letter-spacing: 2px;
			}

			h1 {
				font-size: 22px;
				margin-bottom: 5px;
			}

			.banner-btn-light {
				padding: 15px 28px;
			}
		}

		.laptop, .mobile {
			display: none;
		}
	}
}

@media (max-width: 420px) {
	#banner-two {
		height: 70vh;
	}
}



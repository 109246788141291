#screenshort {
	padding: 100px 0;
	position: relative;
}

.screenshort-slider {
	padding: 0 20px 30px;
}

.screenshort-thumb {
	position: relative;
	box-shadow: 0px 10px 20px 0px rgba(0, 12, 31, 0.1);

	img {
		width: 100%;
	}

	&:before {
		position: absolute;
		content: '';
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		background: rgba($color__theme, 0.8);
		visibility: hidden;
		opacity: 0;
		transition: all 0.3s ease-in-out;
	}

	.popup-btn {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		height: 40px;
		width: 40px;
		border: 1px solid #fff;
		border-radius: 50%;
		text-align: center;
		line-height: 42px;
		color: #fff;
		visibility: hidden;
		opacity: 0;
		transition: all 0.3s ease-in-out;
		font-size: 22px;

		.ei {
			margin-left: -2px;
		}

		&:hover {
			background: $color__theme;
			color: #fff;
			border-color: $color__theme;
		}
	}

	&:hover {
		&:before, .popup-btn {
			visibility: visible;
			opacity: 1;
		}

		&:before {
			top: 15px;
			bottom: 15px;
			left: 15px;
			right: 15px;
		}
	}
}

.swiper-pagination {
	position: absolute;
	left: 50%;
	transform: translateX(-50%);
}

.swiper-pagination-bullet {
	margin: 0 5px;
	position: relative;
	background: #545454;
	transition: all 0.3s ease-in-out;

	&.swiper-pagination-bullet-active {
		background: $color__theme;


		&:before {
			position: absolute;
			content: '';
			height: 16px;
			width: 16px;
			border: 1px solid $color__theme;
			border-radius: 50%;
			left: -4px;
			top: -4px;
		}
	}
}

// .swiper-slide-active {
// 	box-shadow: 0px 20px 30px 0px rgba(1, 0, 57, 0.1);

// }

/* Screenshort Two */
#screenshort-two {
	padding: 100px 0;
	background: #f9fbff;
}


.screenshort-slider-two {
	padding-top: 30px;
	height: 490px;

	.swiper-slide {
		img {
			box-shadow: 0px 3px 4px 0px rgba(1, 0, 57, 0.1);
			width: 100%;
			transform: scale(0.9);
			transition: all 0.3s ease-in-out;
		}

		&.swiper-slide-active {
			img {
				box-shadow: 0px 20px 30px 0px rgba(1, 0, 57, 0.1);
				transform: scale(1);

			}
		}
	}
}


@media (max-width: 1200px) {
	.screenshort-slider-two {
		height: 420px;
	}
}

@media (max-width: 992px) {
	.screenshort-slider-two {
		height: 320px;
	}
}

@media (max-width: 768px) {
	#screenshort, #screenshort-two {
		padding: 50px 0 60px;
	}

	.screenshort-slider-two {
		height: 390px;
	}
}

@media (max-width: 576px) {
	.screenshort-slider-two {
		height: auto;
	}

	.swiper-pagination {
		display: none;
	}
}
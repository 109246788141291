#countup {
	background: #f9fbff;
	padding: 100px 0 70px;

}

.count-thumb {
	position: absolute;
	left: 0;
	top: -220px;
}

.counter-box {
	background: #fff;
	box-shadow: 0px 3px 4px 0px rgba(1, 0, 57, 0.06);
	text-align: center;
	margin-bottom: 30px;
	padding: 35px 0;
	transition: all 0.3s ease-in-out;

	.count  {
		i {
			font-size: 40px;
			color: #6b727f;
			margin-bottom: 25px;
			display: inline-block;
			transition: all 0.3s ease-in-out;
		}

		h2 {
			font-size: 36px;
			font-weight: 800;
			color: #6b727f;
			transition: all 0.3s ease-in-out;
		}

		p {
			font-size: 20px;
			color: #a9aeb8;
			margin: 0;
			font-weight: 500;
			font-family: $font_heading;
		}
	}

	&:hover {
		box-shadow: 0px 10px 20px 0px rgba(1, 0, 57, 0.06);

		.count {
			i {
				color: $color__theme;
			}

			h2 {
				color: $color__theme;
			}
		}
	}

}


@media (max-width: 992px) {
	.count-thumb {
		position: relative;
		top: 0;
		text-align: center;
		max-width: 400px;
		margin: 0 auto 20px;
	}

}

@media (max-width: 768px) {
	#countup {
		padding: 50px 0 20px;
	}
}
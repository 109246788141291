#prosess {
	padding: 100px 0;
	overflow: hidden;
}

.prosess-tabs {
	border-bottom: 1px solid #d7dde9;
	padding-bottom: 10px;

	li {
		margin-right: 20px;

		a  {
			color: #6b727f;
			padding: 0;
			font-size: 14px;
			font-weight: 500;
			position: relative;

			&:after {
				position: absolute;
				content: '';
				background: $color__theme;
				width: 0;
				height: 3px;
				left: 0;
				bottom: -12px;
				transition: all 0.3s ease-in-out;
			}

			&.active {
				color: $color__theme;
				&:after {
					width: 100%;
				}
			}
		}
	}
}

.prosess-tabs-inner {
	margin-top: 40px;

	.title {
		font-size: 30px;
		margin-bottom: 25px;
		margin-top: 0;
		font-weight: 700;
	}

	.tab-content {
		.tab-pane {
			padding: 20px 0;
			p {
				margin-bottom: 20px;

			}

			&.active {
				animation: fadeIn .75s ease forwards;
			}

			.read-btn {
				padding: 12px 25px;
				background: $color__theme;
				color: #fff;
				font-size: 12px;
				text-transform: uppercase;
				font-weight: 600;
				border-radius: 30px;
				display: inline-block;

				i {
					margin-left: 3px;
					transition: all 0.3s ease-in-out;
				}

				&:hover {
					background: #333;

					i {
						margin-left: 7px;
					}
				}
			}
		}
	}
}

@keyframes fadeIn {
	from {
		opacity: 0;
		transform: translate3d(0, 20px, 0);
		-webkit-transform: translate3d(0, 20px, 0);
	}
	to {
		opacity: 1;
		transform: translate3d(0, 0, 0);
		-webkit-transform: translate3d(0, 0, 0);
	}
}

@keyframes fadeOut {
	from {
		opacity: 1;
		transform: translate3d(0, 0, 0);
		-webkit-transform: translate3d(0, 0, 0);
	}
	to {
		opacity: 0;
		transform: translate3d(0, -20px, 0);
		-webkit-transform: translate3d(0, -20px, 0);
	}
}


.prosess-feature-image {
	position: relative;
	height: 560px;

	.img-one, .img-two {
		position: absolute;

	}

	.img-one {
		right: -200px;
		top: -45px;
	}

	.img-two {
		left: 0;
		top: -26px;
	}
}






.tab-box {
	width: 100%;
}
.tab-box {
	.buttons-wrap {
		width: 100%;
		position: relative;
		border-bottom: 1px solid rgba(0, 0, 0, 0.15);

	}

	.buttons {
		width: 100%;
		white-space: nowrap;
		overflow: auto;
		position: relative;
		// margin-bottom: -1px;
		margin: 0;
		padding: 0;
		list-style: none;

		&:after {
			content: '';
			display: table;
			clear: both;
		}

		.line {
			background-color: $color__theme;
			width: 0px;
			height: 3px;
			position: absolute;
			bottom: 0px;
			left: 0;
			-webkit-transition: 0.3s cubic-bezier(0.2, 0.35, 0.25, 1);
			-o-transition: 0.3s cubic-bezier(0.2, 0.35, 0.25, 1);
			transition: 0.3s cubic-bezier(0.2, 0.35, 0.25, 1);
			-webkit-transition-property: width, -webkit-transform;
			transition-property: width, -webkit-transform;
			-o-transition-property: width, transform;
			transition-property: width, transform;
			transition-property: width, transform, -webkit-transform;
		}
	}

	.button {
		width: auto;
		margin: 0 30px 0 0;
		padding: 10px 0;
		font-size: 14px;
		font-weight: 500;
		display: inline-block;
		color: #6b727f;
		clear: none;
		cursor: pointer;
		-webkit-transition: color .15s ease-in-out;
		-o-transition: color .15s ease-in-out;
		transition: color .15s ease-in-out;

		&.active, &:hover {
			color: $color__theme;
		}
	}

	.items {
		position: relative;
		-webkit-transition: height 0.3s cubic-bezier(0.2, 0.35, 0.25, 1);
		-o-transition: height 0.3s cubic-bezier(0.2, 0.35, 0.25, 1);
		transition: height 0.3s cubic-bezier(0.2, 0.35, 0.25, 1);
	}

	.item {
		width: 100%;
		opacity: 0;
		overflow: hidden;
		-webkit-transition: 0.2s cubic-bezier(0.2, 0.35, 0.25, 1);
		-o-transition: 0.2s cubic-bezier(0.2, 0.35, 0.25, 1);
		transition: 0.2s cubic-bezier(0.2, 0.35, 0.25, 1);
		-webkit-transition-property: opacity, visibility;
		-o-transition-property: opacity, visibility;
		transition-property: opacity, visibility;
		position: absolute;
		top: 15px;
		left: 0;
		font-size: 15px;
		font-weight: 400;

		&.active {
			opacity: 1;
			overflow: visible;
			z-index: 2;
			animation: fadeIn .75s ease forwards;
		}

		p {
			margin-bottom: 20px;
		}


		.read-btn {
			padding: 10px 20px;
			background: $color__theme;
			color: #fff;
			font-size: 12px;
			text-transform: uppercase;
			font-weight: 500;
			border-radius: 30px;
			display: inline-block;
			border: 1px solid $color__theme;

			i {
				margin-left: 3px;
				transition: all 0.3s ease-in-out;
			}

			&:hover {
				background: transparent;
				border-color: $color__theme;
				color: $color__theme;

				i {
					margin-left: 7px;
				}
			}
		}

	}
}


.hotspot-wraper {
	position: absolute;
	top: 210px;
	z-index: 9999;
	left: 85px;
	cursor: pointer;


	.hotspot-hover {
		background: #fff;
		padding: 20px 15px;
		text-align: center;
		width: 220px;
		z-index: 99999;
		position: absolute;
		left: -100px;
		top: -100px;
		box-shadow: 0px 30px 50px 0px rgba(1, 0, 57, 0.16);
		opacity: 0;
		visibility: hidden;
		transform: scale(0.8);
		transition: all 0.3s ease-in-out;

		&:after {
			content: '';
			position: absolute;
			bottom: -10px;
			left: 48%;
			transform: translateX(-50%);
			width: 0;
			height: 0;
			border-left: 10px solid transparent;
			border-right: 10px solid transparent;
			border-top: 10px solid #fff
		}

		p {
			margin-bottom: 0;
		}
	}

	&:hover {
		.hotspot-hover {
			opacity: 1;
			visibility: visible;
			transform: scale(1);
		}
	}
}






.pulse-box {
	width: 60px;


	svg {
		width: 10px;

	}
}

/* pulse in SVG */
svg.pulse-svg {
	overflow: visible;
}
svg.pulse-svg .first-circle, svg.pulse-svg .second-circle, svg.pulse-svg .third-circle {
	fill: $color__theme;
	transform: scale(0.5);
	transform-origin: center center;
	animation: pulse-me 3s linear infinite;
}
svg.pulse-svg .second-circle {
	animation-delay: 1s;
}
svg.pulse-svg .third-circle {
	animation-delay: 2s;
}


@keyframes pulse-me {
	0% {
		transform: scale(0.5);
		opacity: 0;
	}
	50% {
		opacity: 0.2;
	}
	70% {
		opacity: 0.09;
	}
	100% {
		transform: scale(5);
		opacity: 0;
	}
}


@media (max-width: 1200px) {
	.prosess-feature-image {
		.img-two {
			top: 50px;
		}
	}

	.hotspot-wraper {
		left: 150px;
		top: 250px;
	}
}

@media (max-width: 991px) {

	.prosess-tabs-inner {
		margin-top: 0;
		margin-bottom: 100px;
	}

	.prosess-feature-image {
		height: 550px;
		.img-two {
			top: -43px;
		}
	}

	.hotspot-wraper {
		left: 41px;
		top: 198px;
	}

}

@media (max-width: 767px) {
	.prosess-feature-image .img-one {
		right: -145px;
		top: -50px;

		img {
			max-width: 80%;
		}
	}

	.hotspot-wraper {
		left: 215px;
		top: 158px;
	}

	#prosess {
		padding: 50px 0;
	}

	.prosess-feature-image {
		height: 430px;
	}
}

@media (max-width: 576px) {
	.prosess-feature-image {
		height: 450px;
	}

	.hotspot-wraper {
		display: none;
	}

	.prosess-feature-image .img-one {
		top: 0
	}

	.prosess-feature-image .img-two {
		top: 30px
	}

	.prosess-tabs-inner {
		.title {
			font-size: 24px;
			margin-bottom: 15px;
		}
	}
}
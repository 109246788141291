#site-footer {
	background: #23273b;
	padding-top: 120px;
	position: fixed;
	bottom: 0;
	width: 100%;
	z-index: -1;
}

.site-footer {
	.widget-about {
		.footer-logo {
			margin-bottom: 20px;
		}

		.content {
			color: #aab0ca;
			font-size: 14px;
			margin-bottom: 30px;
		}
	}

	.widget {
		margin-bottom: 0;

		.widget-title {
			font-size: 20px;
			margin-bottom: 15px;
			color: #fff;
			font-weight: 600;
		}

		.footer-menu {
			margin: 0;
			padding: 0;
			list-style: none;

			li {
				line-height: 30px;

				a {
					color: #aab0ca;
					font-size: 12px;
					text-transform: uppercase;
					position: relative;
					font-family: $font_heading;
					display: inline-block;

					&:after {

						position: absolute;
						content: '';
						display: block;
						bottom: 5px;
						height: 1px;
						left: 0;
						width: 100%;
						background: #fff;
						transform-origin: right center;
						transform: scale(0,1);
						transition: transform .25s cubic-bezier(.37,.31,.2,.85);
					}


					&:hover {
						color: #fff;

						&:after {
							transform-origin: left center;
							transform: scale(1,1);
						}
					}
				}
			}
		}
	}

	.footer-inner-two {
		.widget {
			.widget-title {
				color: #3b566e;
			}

			.footer-menu {
				li {
					a {
						color: #8b8b99;

						&:after {
							background: $color__theme;
						}

						&:hover {
							color: $color__theme;
						}
					}
				}
			}
		}
	}
}

.footer-inner {
	padding-bottom: 70px;
}

.footer-social-link {
	padding: 0;
	list-style: none;
	margin: 0;

	li {
		display: inline-block;
		margin: 0.15em;
		position: relative;
		font-size: 1.2em;
	}

	i {
		color: #fff;
		position: absolute;
		top: 50%;
		left: 50%;
		transition: all 265ms ease-out;
		transform: translate(-50%, -50%);
	}

	a {
		display: inline-block;
		position: relative;

		&:before {
			transform: scale(1);
			-ms-transform: scale(1);
			-webkit-transform: scale(1);
			content: " ";
			width: 35px;
			height: 35px;
			border-radius: 100%;
			display: block;
			background: linear-gradient(45deg, #ff003c, #c648c8);
			transition: all 600ms ease-out;
		}


		&:hover{
			&:before {
				transform: rotate(-180deg);
				-ms-transform: rotate(-180deg);
				-webkit-transform: scale(-180deg);
				border-radius: 100%;
			}
		}

	}

	a:before {
		background: linear-gradient(45deg, #7b00e0, #ae31d9);
		border-radius: 10%;
	}
}

.site-info {
	padding: 16px 0;
	border-top: 1px solid #363b50;
	text-align: center;

	.copyright {
		margin: 0;
		font-size: 12px;
		color: #858aa5;

		a {
			color: #fff;
			font-weight: 500;

			&:hover {
				color: #b9b9b9;
			}
		}
	}
}

/* Footer Two */
#site-footer-two {
	background: #f3f3fc;
	position: relative;
	overflow: hidden;

	.widget-about {
		p {
			color: #8b8b99;
		}
	}

	.site-info {
		border-color: #e3e3f2;

		.copyright {
			text-align: left;

			a {
				color: $color__theme;
			}
		}
	}
}

.footer-inner-two {
	padding: 100px 0 50px;
	position: relative;
}

.footer-contact-info {
	margin: 0;
	padding: 0;
	list-style: none;

	li {
		line-height: 35px;
		font-size: 15px;
		font-family: $font_heading;

		span {
			font-weight: 600;
			color: #466077;
			font-size: 16px;
		}
	}
}

.follow-us {
	display: flex;
	margin-top: 10px;

	span {
		margin-right: 10px;
		color: #466077;
		font-weight: 16px;
		font-weight: 600;
	}
}

.footer-social-two {
	margin: 0;
	padding: 0;
	list-style: none;

	li {
		display: inline-block;
		margin-right: 10px;
		a {
			display: inline-block;
			color: #8b8b99;

			&:hover {
				color: $color__theme;
			}
		}
	}

}

.footer-menu-bottom {
	margin: 0;
	padding: 0;
	list-style: none;
	text-align: right;

	li {
		display: inline-block;
		margin-right: 40px;

		&:last-child {
			margin-right: 0;
		}

		a {
			color: #8b8b99;
			font-size: 12px;
			text-transform: uppercase;

			&:hover {
				color: $color__theme;
			}
		}
	}
}

.bubble1 {
	top: -85px;
	left: -120px;
	position: absolute;
}

.bubble2 {
	top: 10%;
	left: -170px;
	position: absolute;
}

.bubble3 {
	bottom: -16%;
	right: -275px;
	position: absolute;
}

.bubble4 {
	bottom: -54%;
	right: -185px;
	position: absolute;
}

@media (max-width: 991px) {
	.widget {
		margin-bottom: 30px;
	}
}

@media (max-width: 768px) {
	#site-footer {
		position: static;
		padding-top: 50px;
	}

	.footer-inner {
		padding-bottom: 20px;
	}

	#site-footer-two {
		.site-info {
			.copyright {
				text-align: center;
			}
		}
	}

	.footer-menu-bottom {
		text-align: center;

		li {
			margin-right: 20px;
			font-size: 11px;
		}
	}
}
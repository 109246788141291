/*--------------------------------------------------------------
  ##  Header
  --------------------------------------------------------------*/
@import "header";

/*--------------------------------------------------------------
  ##  Banner
  --------------------------------------------------------------*/
@import "banner";

/*--------------------------------------------------------------
  ##  Showcase
  --------------------------------------------------------------*/
@import "showcase";

/*--------------------------------------------------------------
  ##  Feature
  --------------------------------------------------------------*/
@import "feature";

/*--------------------------------------------------------------
  ##  Screenshort
  --------------------------------------------------------------*/
@import "screenshort";

/*--------------------------------------------------------------
  ##  Faq
  --------------------------------------------------------------*/
@import "faq";

/*--------------------------------------------------------------
  ##  Pricing Table
  --------------------------------------------------------------*/
@import "pricing-table";

/*--------------------------------------------------------------
  ##  Download
  --------------------------------------------------------------*/
@import "download";

/*--------------------------------------------------------------
  ##  Testimonial
  --------------------------------------------------------------*/
@import "testimonial";

/*--------------------------------------------------------------
  ##  News
  --------------------------------------------------------------*/
@import "news";

/*--------------------------------------------------------------
  ##  Team
  --------------------------------------------------------------*/
@import "team";

/*--------------------------------------------------------------
  ##  Newsletter
  --------------------------------------------------------------*/
@import "news-letter";

/*--------------------------------------------------------------
  ##  Prosses
  --------------------------------------------------------------*/
@import "prosess";

/*--------------------------------------------------------------
  ##  Countup
  --------------------------------------------------------------*/
@import "countup";

/*--------------------------------------------------------------
  ##  Contact
  --------------------------------------------------------------*/
@import "contact";

/*--------------------------------------------------------------
  ##  Widgets
  --------------------------------------------------------------*/
@import "widgets";


/*--------------------------------------------------------------
  ##  Page Header
  --------------------------------------------------------------*/
@import "page-header";

/*--------------------------------------------------------------
  ##  Footer
  --------------------------------------------------------------*/
@import "footer";
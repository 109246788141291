#news {
	padding: 100px 0 70px;
}

.news-grid {
	margin-bottom: 30px;

	.features-image {
		margin-bottom: 20px;
		overflow: hidden;
		position: relative;

		a {

			&:before {
				position: absolute;
				content: '';
				height: 100%;
				width: 100%;
				top: 0;
				left: 0;
				background: rgba(0,0,0,0.4);
				z-index: 222;
				opacity: 0;
				transition: all 0.3s ease-in-out;

			}
		}

		img {
			transition: all 0.3s ease-in-out;
			z-index: 11;
		}

	}

	.entry-header {
		.post-meta {
			margin: 0 0 10px;
			padding: 0;
			list-style: none;

			li {
				display: inline-block;
				color: #99a1af;
				position: relative;
				margin-right: 15px;

				&:after {
					position: absolute;
					content: '';
					height: 12px;
					width: 1px;
					right: -10px;
					top: 7px;
					background: #99a1af;
				}

				&:last-child {
					&:after {
						display: none;
					}
				}

				a {
					color: #99a1af;
					font-weight: 500;
					font-size: 14px;

					&:hover {
						color: $color__theme;
					}
				}
			}
		}

		.entry-title {
			font-size: 20px;
			line-height: 30px;
			font-weight: 500;
			font-family: $font_primary;
			margin-bottom: 15px;

			a {
				color: $color__heading;

				&:hover {
					color: $color__theme;
				}
			}
		}

		.read-btn {
			text-transform: uppercase;
			font-size: 12px;
			color: #8b8b99;
			font-weight: 500;

			i {
				margin-left: 3px;
				transition: all 0.23s ease-in-out;
			}

			&:hover {
				color: $color__theme;

				i {
					margin-left: 5px;
				}
			}
		}
	}

	&:hover {
		.features-image {

			a {
				&:before {
					opacity: 1;
				}
			}

			img {
				transform: scale(1.05);
			}
		}
	}
}


#blog-post {
	padding: 100px 0;
}

.post {
	margin-bottom: 40px;
	.features-image {
		position: relative;

		img {
			max-width: 100%;
		}

		.posttype {
			position: absolute;
			top: 30px;
			left: 20px;
			height: 60px;
			width: 60px;
			font-size: 24px;
			color: #fff;
			text-align: center;
			line-height: 60px;
			background: rgba(27, 29, 34, 0.702);
			border-radius: 3px;
		}
	}

	.post-content {
		padding: 20px 0 40px 40px;
		border-bottom: 1px solid #e3e6ed;
	}



	.post-title {
		font-size: 30px;
		line-height: 40px;

		a {
			color: #282835;

			&:hover {
				color: $color__theme;
			}
		}
	}

	.read-btn {
		font-size: 12px;
		color: #43464c;
		position: relative;
		text-transform: uppercase;
		font-weight: 600;

		i {
			transition: all 0.3s ease-in-out;
		}

		&:after {
			position: absolute;
			content: '';
			width: 77px;
			height: 1px;
			background: #43464c;;
			left: 0;
			bottom: 0;

		}

		&:hover {
			color: $color__theme;

			&:after {
				background: $color__theme;
			}

			i {
				margin-left: 3px;
			}
		}
	}

	&.link {
		background: #f9f9f9;

		.post-content {
			border-bottom: 0;
			padding: 60px 50px;

			.link-icon {
				position: absolute;
				right: 40px;
				font-size: 40px;
			}
		}

	}
}

.post-meta {
	margin-left: 0;
	margin-bottom: 20px;
	padding: 0;
	list-style: none;

	li {
		display: inline-block;
		margin-right: 20px;
		position: relative;
		color: #656971;

		&:after {
			position: absolute;
			content: '';
			height: 12px;
			width: 2px;
			top: 7px;
			right: -15px;
			background: #656971;;
		}

		a {
			color: #656971;
			font-size: 14px;

			&:hover {
				color: $color__theme;
			}
		}

		&:last-child {
			&:after {
				display: none;
			}
		}
	}
}


.post-pagination {
	margin: 0;
	padding: 0;
	list-style: none;

	li {
		display: inline-block;

		a {
			height: 44px;
			width: 44px;
			text-align: center;
			line-height: 44px;
			font-size: 14px;
			color: #43464c;
			border: 1px solid #cbd0dd;
			display: inline-block;
			border-radius: 50%;
			margin-right: 10px;
			transition: all 0.3s ease-in-out;

			&:hover, &.active {
				background: $color__theme;
				border-color: $color__theme;
				color: #fff;
			}
		}
	}
}

.youtube-banner {
	height: 100%;
	overflow: hidden;
}

.youtube-wrapper {
	position: relative;
	padding-bottom: 59.7%;
	height: 0;
	overflow: hidden;
	max-width: 100%;
	background-color: #191a1c;
	cursor: pointer;

	.posttype {
		z-index: 2;
	}


	&:before {
		content: "\45";
		font-family: 'ElegantIcons';
		font-size: 50px;
		// margin-top: 7px;
		// margin-left: 3px;
		position: absolute;
		top: 50%;
		left: 50%;
		color: #fff;
		z-index: 2;
		cursor: pointer;
		visibility: visible;
		transform: translate3d(-50%, -50%, 0) scale(1);
		-moz-transform: translate3d(-50%, -50%, 0);
		-webkit-transform: translate3d(-50%, -50%, 0);
		transition: transform .2s, opacity .2s;
		-moz-transition: transform .2s, opacity .2s;
		-webkit-transition: transform .2s, opacity .2s;
		height: 80px;
		width: 80px;
		text-align: center;
		line-height: 80px;
		border: 1px solid rgba(255, 255, 255, 0.502);
		background: rgba(255, 255, 255, 0.2);
		border-radius: 50%;

	}

	&.reveal:after, &.reveal:before, &.reveal .blog-content {
		opacity: 0;
		visibility: hidden;
		transition: visibility .4s, opacity .4s;
	}

	&.reveal {
		iframe {
			visibility: visible;
		}

		.posttype {
			opacity: 0;
		}
	}

	iframe,object, embed {
		height: 100%;
		width: 100%;
		position: absolute;
		top: 0;
		left: 0;
		border: none;
		visibility: hidden;
	}
}


.Ellipse {
	border-width: 2px;
	border-color: rgb(255, 255, 255);
	border-style: solid;
	border-radius: 50%;
	background-color: rgba(255, 255, 255, 0.2);
	opacity: 0.502;
	position: absolute;
	left: 700px;
	top: 1610px;
	width: 76px;
	height: 76px;
	z-index: 210;
}


.youtube-wrapper {
	.youtube-poster {
		height: 440px;
		background-position: center;
		background-size: cover;
	}
}


/*
*
* Blog Single
*
*/

#blog-post-single {
	padding: 100px 0;
}

.post.post-single {
	margin-bottom: 40px;
	.post-content{
		padding: 0 0 20px 0;
		margin-top: 30px;

	}

	.post-title {
		margin-bottom: 20px;
	}

	.post-meta {
		padding-bottom: 20px;
		border-bottom: 1px solid #e3e6ed;
	}

	h3 {
		font-size: 24px;
		font-weight: 500;
		color: #1b1d22;
		line-height: 1.5;
		font-style: italic;
	}
}

.post-inner-image {
	display: flex;
	flex-wrap: wrap;
	margin: 20px 0;

	.image-wrap {
		width: calc(50% - 10px);
		margin-right: 10px;
	}
}


.post-footer {
	display: flex;
	flex-wrap: wrap;
	padding-top: 15px;

	.post-share-inner {
		width: 75%;
	}

	.post-navigation {
		width: 25%;
	}

	.post-share-inner {
		display: flex;
	}

	.post-share {
		margin: 0 0 0 10px;
		padding: 0;
		list-style: none;

		li {
			display: inline-block;
			margin-right: 10px;
			a {
				color: #9b9da3;
				font-size: 16px;

				&:hover {
					color: $color__theme;
				}
			}
		}
	}

	.post-navigation {
		a {
			font-size: 12px;
			font-weight: 600;
			font-family: $font_heading;
			color: #43464c;
			text-transform: uppercase;

			i {
				transition: all .3s ease-in-out;
			}

			&:hover {
				color: $color__theme;
				i {
					margin-left: 3px;
				}
			}
		}
	}
}

/*
*
* Author
*
*/

.gp_appxbe_post_author_box {
	padding: 10px;
	border: 1px solid #eee;
	display: flex;
	flex-wrap: wrap;
	border-radius: 5px;
	margin-bottom: 60px;

	.profile_image {
		width: 100px;
		margin-right: 20px;
		border-radius: 50%;

		img {
			border-radius: 50%;
		}
	}

	.profile_content {
		flex: 1;
	}

	.profile_name {
		font-size: 18px;
	}

	.profile_bio {
		margin-bottom: 10px;
	}

	.gp-author-social-link {
		margin: 0;
		padding: 0;
		list-style: none;

		li {
			display: inline-block;
			margin-right: 10px;

			a {
				display: block;
				font-size: 16px;
				color: #43464c;

				&:hover {
					color: $color__theme;
				}
			}
		}
	}
}

/*
*
* Comments
*
*/

#comments {
	margin-top: 30px;

	.comment-reply-title {
		font-size: 22px;
		margin-bottom: 20px;
		padding-bottom: 10px;
		color: #1b1d22;
		font-weight: 600;
		position: relative;

		&:after {
			position: absolute;
			content: '';
			left: 0;
			bottom: 0;
			height: 2px;
			width: 80px;
			background-image: $color_gradient_two;
		}
	}
}


.comment-list {
	margin: 0;
	padding: 0;
	list-style: none;

	.comment {
		.comment-body {
			padding-bottom: 30px;
			position: relative;
			display: flex;
			flex-wrap: wrap;

			.comment-author {
				width: 80px;
				height: 80px;
				border-radius: 50%;
				margin-right: 20px;

				img {
					border-radius: 50%;
				}
			}

			.comment-content {
				flex: 1;
				border-bottom: 1px solid #e3e6ed;
				.fn {
					font-size: 18px;
					font-weight: 500;
					color: #1b1d22;
					display: inline-block;
				}

				p {
					margin-bottom: 30px;
					font-family: $font_heading;
					color: #656971;
					font-size: 14px;
				}

				.comment-metadata {
					display: inline-block;
					position: relative;
					margin-left: 20px;

					a {
						color: #656971;
						font-size: 15px;
						font-family: $font_heading;
					}

					&:before {
						position: absolute;
						content: '';
						left: -12px;
						top: 6px;
						height: 12px;
						width: 1px;
						background:	#919499;
					}
				}
			}

			.reply {
				position: absolute;
				right: 0;
				bottom: 35px;

				a {
					font-size: 12px;
					font-weight: 600;
					color: #656971;
					text-transform: uppercase;

					i {
						margin-right: 5px;
					}

					&:hover {
						color: $color__theme;
					}
				}
			}
		}
	}

	.children {
		margin: 0;
		list-style: none;
	}
}

.comment-form {


	.comment-form-comment {
		width: 100%;
	}

	.comment-form-author, .comment-form-email  {
		width: 50%;
		float: left;

	}

	.comment-form-author {
		padding-right: 15px;
	}

	.comment-form-email {
		padding-left: 15px;
	}

	.comment-form-url {
		width: 100%;
		margin-bottom: 30px;
	}

	input {
		height: 60px;
	}

	textarea {
		height: 200px;
	}

	textarea, input {
		width: 100%;
		background: #f9f9f9;
		border: 1px solid #e9edf6;
		outline: 0;
		transition: all 0.3s ease-in-out;
		padding: 10px 15px;
		border-radius: 3px;
		font-family: $font_heading;

		&::placeholder {
			color: #656971;
		}

		&:hover, &:focus {
			outline: 0;
			box-shadow: none;
			background: #fff;
			box-shadow: -10px 17.321px 40px 0px rgba(14, 0, 40, 0.2);

		}
	}
}

.form-submit {


	#submit {
		background:	$color__theme;
		padding: 14px 40px;
		text-align: center;
		border: 0;
		color: #fff;
		border-radius: 30px;
		height: auto;
		font-family: $font_heading;
		text-transform: uppercase;
		font-size: 12px;
		font-weight: 600;
		cursor: pointer;
		transition: all 0.3s ease-in-out;
		letter-spacing: 2px;

		i {
			transition: all 0.3s ease-in-out;
		}

		&:hover {
			box-shadow: none;
			background: #1b1d22;
			color: #fff;

			i {
				margin-left: 3px;
			}
		}
	}
}


@media (max-width: 992px) {
	.sidebar {
		margin-top: 30px;
	}
}

@media (max-width: 768px) {
	#news {
		padding: 50px 0 20px;
	}

	.post .post-title {
		font-size: 24px;
		line-height: 30px;
	}
}

@media (max-width: 576px) {
	#news {
		padding: 50px 0 20px;
	}

	.post .post-content {
		padding: 20px;
	}

	.post .post-title {
		font-size: 20px;
		line-height: 28px;
	}

	.post-meta li {
		margin-right: 15px;

		&:after {
			right: -10px;
		}
	}

	.post-meta li a {
		font-size: 12px;
	}

	.comment-form .comment-form-author, .comment-form .comment-form-email {
		width: 100%;
		padding: 0;
	}

	.post.link .post-content {
		padding: 30px;
	}
}
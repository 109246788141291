$font_primary: 'Montserrat', sans-serif;
$font_heading: 'Poppins', sans-serif;

$color_gradient: linear-gradient( -90deg, rgb(50,36,175) 0%, rgb(201,109,216) 100%);
$color_gradient_two: linear-gradient( 40deg, rgb(71,69,228) 0%, rgb(24,207,245) 100%);

$color__theme: #3224af;
$color__heading: #282835;

$button__bg: linear-gradient(to right, rgb(99,82,251) 0%, rgb(201,109,216) 50%, rgb(99,82,251) 100%);


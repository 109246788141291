#pricing {
	padding: 100px 0 70px;

	.animate-bg {

		li:nth-child(1) {
			left: 6%;
			bottom: 30%
		}

		li:nth-child(4) {
			top: 60%;
		}

		li:nth-child(6) {
			top: 50%;
			left: 16%;
		}
	}
}

.procing-table {
	text-align: center;
	position: relative;
	padding: 45px 40px 60px;
	border: 1px solid #f0f0fa;
	background: #fafcff;
	transition: all 0.3s ease-in-out;
	margin-bottom: 30px;

	&.featured {

		&:before {
			content: '';
			position: absolute;
			background-image: url(../../media/pricing/1.png);
			background-repeat: no-repeat;
			background-position: right top;
			width: 100%;
			height: 100%;
			right: 0;
			top: 0;
		}
	}

	.price-header {
		border-bottom: 1px solid #e8e8f0;
		padding-bottom: 10px;
		margin-bottom: 20px;

		.price-title {
			font-size: 20px;
			font-weight: 500;
			margin-top: 0;
			margin-bottom: 15px;
		}

		.sub-title {
			margin-bottom: 10px ;
			line-height: 14px;
		}
	}

	.price-period {
		margin-bottom: 25px;

		.price {
			font-size: 50px;
			margin: 0;
			transition: all 0.3s ease-in-out;

			.period {
				font-size: 14px;
				color: #8b8b99;
				font-family: $font_primary;
			}
		}
	}

	.price-feature {
		margin: 0 0 40px;
		padding: 0;
		list-style: none;
		position: relative;

		li {
			font-size: 16px;
			line-height: 40px;
			color: #8b8b99;
			font-weight: 300;
			font-family: $font_primary;

			i {
				margin-right: 20px;

				color: $color__theme;


				&.ei-icon_close {
					display: inline-block;
					color: #fa4b4b;
				}
			}
		}
	}

	.buy-btn {
		padding: 11px 25px;
		border: 1px solid $color__theme;
		color: $color__theme;
		display: inline-block;
		font-size: 12px;
		font-weight: 500;
		text-transform: uppercase;
		font-family: $font_primary;
		position: relative;
		transition: all 0.3s ease-in-out;
		border-radius: 2px;
		backface-visibility: hidden;


		&:hover {
			color: #fff;
			background-image: $color_gradient;


		}

	}

	&.price-two {
		&.featured {
			&:before {
				background-image: url(../../media/pricing/2.png);
			}

			&:hover {
				.price-period {
					.price {
						color: $color__theme;
					}
				}
			}
		}

		.price-feature {
			li {
				font-size: 15px;
			}
		}

		.price-feature {
			margin-bottom: 35px;
		}

		.buy-btn {
			border-radius: 30px;
			border-color: #cfcefe;
			color: $color__theme;
			background: #f3f3fe;


			i {
				margin-left: 3px;
				transition: all 0.3s ease-in-out;
			}

			&:hover {
				background: $color__theme;
				color: #fff;

				i {
					margin-left: 9px;
				}
			}
		}
	}

	&:hover {
		box-shadow: 0px 30px 50px 0px rgba(0, 0, 29, 0.1);
	}


}

#pricing-two {
	padding: 100px 0 70px;

}

@media (max-width: 768px) {
	#pricing, #pricing-two {
		padding: 50px 0 20px;
	}

	.procing-table{
		&.featured {
			&:before {
				background-size: cover;
				background-position: center center;
			}
		}
	}
}


#app-showcase {
	padding: 100px 0 70px;
}


.shocase-content {
	margin-top: 90px;

	.showcase-title {
		font-size: 36px;
		font-weight: 600;
		color: #282835;
		margin-bottom: 20px;

	}

	p {
		margin-bottom: 20px;
	}

	.gp-btn {
		margin-top: 20px;
	}

}

.showcase-image {

	img {
		z-index: 22;
		position: relative;
	}
}

.animate-bg {
	margin: 0;
	padding: 0;
	list-style: none;

	li {
		position: absolute;
		z-index: 0;

		&:nth-child(1) {
			bottom: -50px;
			left: 6%;
		}

		&:nth-child(2) {
			top: 54%;
			left: -75px;
		}

		&:nth-child(3) {
			top: 110px;
			left: 15%;
		}

		&:nth-child(4) {
			top: 35%;
			right: 18%;
		}

		&:nth-child(5) {
			bottom: 9%;
			right: 37%;
		}
	}
}

@media (max-width: 768px) {
	#app-showcase {
		padding: 50px 0 30px;
	}

	.showcase-image {
		margin-top: 30px;
	}

	.shocase-content {
		margin-top: 0;

		.showcase-title {
			font-size: 26px;
		}
	}
}
#team {
	padding: 100px 0 70px;

	.more-btn {
		display: block;
		text-align: center;
		margin-top: 50px;
		font-size: 14px;
		font-weight: 500;
		color: #b0b0bd;

		i {
			margin-left: 3px;
			transition: all 0.3s ease-in-out;
		}

		&:hover {
			color: $color__theme;

			i {
				margin-left: 7px;
			}
		}
	}
}


.team-member {
	margin-bottom: 30px;

	.member-avatar {
		position: relative;

		&:before {
			position: absolute;
			content: '';
			width: 100%;
			height: 100%;
			left: 0;
			top: 0;
			background-image: $color_gradient_two;
			opacity: 0;
			z-index: 0;
			transition: all 0.6s cubic-bezier(0.23, 1, 0.32, 1);
			transform: scale(0.9);
		}

		.team-details {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			opacity: 0;
			visibility: hidden;

			.content {
				position: absolute;
				z-index: 222;
				text-align: center;
				padding: 20px;
				top: 50%;
				transform: translateY(-50%);

				p {
					color: #fff;
					margin-bottom: 20px;
					opacity: 0;
					transition: all 0.5s ease-in-out;
				}

				.member-profile {
					margin: 0;
					padding: 0;
					list-style: none;

					li {
						display: inline-block;
						transform: scale(0);
						transition: all 0.3s ease-in-out;
						transition: all 0.45s cubic-bezier(0.23, 0.88, 0.34, 0.99);

						a {
							height: 40px;
							width: 40px;
							font-size: 14px;
							text-align: center;
							line-height: 40px;
							color: #fff;
							border-radius: 50%;
							border: 1px solid #fff;
							display: inline-block;
							margin: 0 5px;
							transition: all 0.3s ease-in-out;
							overflow: hidden;


							&:hover {
								background: #fff;
								color: $color__theme;
							}
						}
					}
				}
			}
		}
	}

	.member-bio {
		margin-top: 20px;
		text-align: center;

		.name {
			font-size: 16px;
			font-weight: 600;
			margin-top: 0;
			color: #282835;
			margin-bottom: 0;
		}
		.position {
			color: #8b8b99;
			font-size: 14px;
		}
	}

	&:hover {
		.member-avatar {
			&:before {
				opacity: 0.9;
				transform: scale(1);
			}

			.team-details {
				opacity: 1;
				visibility: visible;

				.content {

					p {
						opacity: 1;
					}

					.member-profile{

						li {
							transform: scale(1);

							&:nth-child(1) {
								transition-delay: .07692s;
							}


							&:nth-child(2) {
								transition-delay: .15692s;
							}


							&:nth-child(3) {
								transition-delay: .21692s;
							}
						}
					}
				}
			}
		}
	}
}

@media (max-width: 768px) {
	#team {
		padding: 50px 0;

		.more-btn {
			margin-top: 0;
		}
	}
}
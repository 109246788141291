#feature {
	padding: 100px 0;
	background: #fafcff;
	position: relative;
}

.feature-box-inner {
	margin-top: 100px;
}

.feature-box {
	margin-bottom: 60px;

	&.left-icon {
		text-align: right;
	}

	.icon-container {
		margin-bottom: 20px;

		i {
			font-size: 40px;
			background-image: $color_gradient;
			-webkit-background-clip: text;
			-webkit-text-fill-color: transparent;
		}
	}

	.content {
		h3 {
			font-size: 20px;
			font-weight: 600;
			transition: all 0.3s ease-in-out;
			margin-bottom: 15px;

		}
	}

	&:hover {
		.content {
			h3 {
				color: $color__theme;
			}
		}
	}
}

.feature-image {
	img {
		position: relative;
		z-index: 11;
	}
}


/* Feature Box Two */
#swape {
	padding: 100px 0 70px;
	background: #f9fbff;
}

.feature-box-two {
	margin-bottom: 30px;

	.icon-container {
		height: 80px;
		width: 80px;
		border: 1px solid rgb(207, 206, 254);
		border-radius: 50%;
		text-align: center;
		line-height: 80px;
		margin: 0 auto 30px;
		background-color: rgb(243, 243, 254);
		position: relative;

		&:before {
			content: '';
			position: absolute;
			top: 5px;
			right: 5px;
			left: 5px;
			bottom: 5px;
			background: $color__theme;
			border-radius: 50%;
			z-index: 0;
			opacity: 1;
			transform: scale(0);
			transition: all 0.3s ease-in-out;

		}



		i {
			font-size: 32px;
			color: $color__theme;
			z-index: 1;
			position: relative;
			line-height: 80px;
		}
	}

	.content {
		h3 {
			font-size: 20px;
			color: #282835;
			margin-bottom: 15px;
			margin-top: 0;
			transition: all 0.3s ease-in-out;
		}
	}

	&:hover {
		.icon-container {
			background-color: rgba(71, 69, 228, 0.1);
			border-color: transparent;

			&:before {
				transform: scale(1);
			}

			i {
				color: #fff;
			}
		}

		.content {
			h3 {
				color: $color__theme;
			}
		}
	}

	&.box-three {
		padding: 30px 30px 0;;
		margin: 0;

		p {
			margin-bottom: 30px;
		}

	}
}

/* Feature Three */
#feature-two {
	padding: 100px 0 70px;
}

.b-br {
	border-bottom: 1px solid #eaeff8;
	border-right: 1px solid #eaeff8;
}

.b-r {
	border-right: 1px solid #eaeff8;
}

.bdr-none {
	border-right: 0;
}


@media (min-width: 768px) and (max-width: 992px) {
	.feature-box-inner {
		margin-top: 20px;
	}

	.feature-box {
		margin-bottom: 30px;

		.icon-container {
			margin-bottom: 10px;
		}

		.content {

			h3 {
				margin-bottom: 10px;
			}

			p {
				line-height: 22px;
			}
		}
	}

	.br-n {
		border-right: 0 !important;
	}

	.b-r {
		border-right: 1px solid #eaeff8
	}

	.bb-b  {
		border-bottom: 1px solid #eaeff8
	}
}

@media (max-width: 768px) {

	#feature {
		padding: 50px 0;
	}


	.feature-box-inner {
		margin-top: 30px;
	}

	.feature-box {
		margin-bottom: 30px;
	}


	#feature-two {
		padding: 50px 0 20px;
	}

	#swape {
		padding: 50px 0 20px;
	}

	.sm-brn {
		border-right: 0;
	}

	.sm-br {
		border-bottom: 1px solid #eaeff8
	}
}
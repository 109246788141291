#newsletter {
	padding: 100px 0;
}

.newsletter-form {
	max-width: 765px;
	background: #fff;
	border: 1px solid #f0f0fa;
	padding: 10px 10px 10px 35px;
	margin: 0 auto;


	i {
		font-size: 27px;
		color: #ecd3f0;
		line-height: 54px;
	}

	.fa-spin {
		display: none;
	}

	input {
		border: 0;
		background: transparent;
		outline: 0;
		color: $color__heading;
		font-size: 16px;
		font-weight: 500;
		height: 60px;
		width: 70%;
		display: inline-block;

		&::placeholder {
			color: #bbbbc9;
		}

		&:focus {
			box-shadow: none;
			border: 0;
		}
	}

	#newsletter-submit {
		padding: 20px 38px;
		background-image: $button__bg;
		background-size: 200% auto;
		text-transform: uppercase;
		color: #fff;
		font-size: 12px;
		letter-spacing: 3px;
		outline: 0;
		border: 0;
		margin-top: -5px;
		display: inline-block;
		transition: all 0.3s ease-in-out;

		&:hover, &:focus {
			box-shadow: none;
			background-position: right center;
		}
	}
}

.form-result {
	display: none;
	width: 90%;
	margin: 10px auto;
}

@media (max-width: 768px) {
	#newsletter {
		padding: 50px 0;
	}

}

@media (max-width: 576px) {
	.newsletter-form {
		padding: 10px;

		i {
			line-height: 36px;
			font-size: 20px;
		}

		input {
			height: 40px;
			font-size: 14px;
		}

		#newsletter-submit {
			padding: 13px 10px;
			height: 40px;
			letter-spacing: 0;
		}
	}
}
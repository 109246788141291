#contact {
	background: #f9fbff;
	padding: 100px 0;
}

.width-half {
	width: 50%;
	float: left;
	padding: 0 15px;
}

#appxbe-contact-form {

	input {
		width: 100%;
		height: 60px;
		background: #fff;
		box-shadow: 0px 2px 4px 0px rgba(1, 0, 57, 0.08);
		border-radius: 30px;
		border: 0;
		padding: 20px 30px;
		outline: 0;
		margin-bottom: 20px;
	}

	textarea {
		width: 100%;
		height: 150px;
		background: #fff;
		box-shadow: 0px 2px 4px 0px rgba(1, 0, 57, 0.08);
		border-radius: 30px;
		outline: 0;
		padding: 20px;
		border: 0;
		margin-bottom: 10px;
	}

}

#contact-submit {
	display: block;
	border: 0;
	background: transparent;
	background-image: $color_gradient_two;
	width: 100%;
	padding: 15px;
	text-align: center;
	color: #fff;
	border-radius: 30px;
	letter-spacing: 6px;
	text-transform: uppercase;
	font-size: 14px;
	font-weight: 500;
	margin-top: 20px;
	outline: 0;
	cursor: pointer;
	border: 1px solid transparent;
	transition: all 0.3s ease-in-out;

	&:hover {
		background-image: none;
		border: 1px solid $color__theme;
		color: $color__theme;
	}
}

.gmap3-area {
	height: 410px;
	border: 5px solid #fff;
	box-shadow: 0 5px 10px rgba(0,0,0,0.1);
}


@media (max-width: 992px) {

	#contact-submit {
		margin-bottom: 30px;
	}
}

@media (max-width: 768px) {
	#contact {
		padding: 50px 0;
	}


}

/*!
  Theme Name: Appxbe
  Theme URI: http://html.gptheme.co/appxbe
  Author: GPTheme
  Author URI: http://www.gptheme.co/
  Description: App Landing HTML Template
  Version: 1.0.0
  License: GNU General Public License v2 or later
  License URI: http://www.gnu.org/licenses/gpl-2.0.html
  Text Domain: appxbe
  Tags: game,business,coporate,startup
  */
/*--------------------------------------------------------------
  >>> TABLE OF CONTENTS:
  ----------------------------------------------------------------
  # Base
  # Sections
    ## Header
    ## Banner
    ## Showcase
    ## Feature
    ## Screenshort
    ## Faq
    ## Pricing-table
    ## Download
    ## Testimonial
    ## News
    ## Team
    ## News-letter
    ## Prosess
    ## Countup
    ## Contact
    ## Contact
    ## Footer

  --------------------------------------------------------------*/
/* Fool-proof @font-face */
/* Based on http://coding.smashingmagazine.com/2013/02/14/setting-weights-and-styles-at-font-face-declaration/ */
/* Front */
@-webkit-keyframes slide-down {
  0% {
    opacity: 1;
  }
  90% {
    opacity: 0;
  }
}

@-moz-keyframes slide-down {
  0% {
    opacity: 1;
  }
  90% {
    opacity: 0;
  }
}

@-ms-keyframes slide-down {
  0% {
    opacity: 1;
  }
  90% {
    opacity: 0;
  }
}

@-o-keyframes slide-down {
  0% {
    opacity: 1;
  }
  90% {
    opacity: 0;
  }
}

@keyframes slide-down {
  0% {
    opacity: 1;
  }
  90% {
    opacity: 0;
  }
}

.element {
  width: 100px;
  height: 100px;
  background: black;
  -webkit-animation: slide-down 5s 3;
  -moz-animation: slide-down 5s 3;
  -ms-animation: slide-down 5s 3;
  -o-animation: slide-down 5s 3;
  animation: slide-down 5s 3;
}

.visually-hidden {
  margin: -1px;
  padding: 0;
  width: 1px;
  height: 1px;
  overflow: hidden;
  clip: rect(0 0 0 0);
  clip: rect(0, 0, 0, 0);
  position: absolute;
}

body {
  font-family: "Montserrat", sans-serif;
  color: #8b8b99;
  font-size: 15px;
  line-height: 26px;
  overflow-x: hidden;
}

.starter-template {
  padding: 40px 15px;
  text-align: center;
}

svg {
  display: block;
}

a {
  transition: all 0.15s ease-in-out;
}

a,
a:hover {
  text-decoration: none;
}

img {
  max-width: 100%;
  height: auto;
}

h1, h2, h3, h4, h5, h6 {
  font-family: "Poppins", sans-serif;
  color: #282835;
  margin-top: 0;
  font-weight: 600;
}

section {
  position: relative;
}

#site {
  background: #fff;
}

@media (max-width: 768px) {
  #site {
    margin-bottom: 0 !important;
  }
  #site-footer {
    position: static;
  }
}

.gp-btn {
  display: inline-block;
  background-image: linear-gradient(to right, #6352fb 0%, #c96dd8 50%, #6352fb 100%);
  background-size: 200% auto;
  color: #fff;
  padding: 14px 23px;
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
  transition: all 0.3s ease-in-out;
  transition: 0.5s;
  border-radius: 2px;
}

.gp-btn:hover {
  background-position: right center;
  color: #fff;
}

.section-title {
  text-align: center;
}

.section-title h2 {
  font-size: 30px;
  line-height: 30px;
  color: #282835;
  padding-bottom: 10px;
  margin-bottom: 50px;
  position: relative;
  display: inline-block;
  font-weight: 700;
}

.section-title h2:before, .section-title h2:after {
  position: absolute;
  content: '';
  width: 70px;
  height: 1px;
  background-image: linear-gradient(-90deg, #3224af 0%, #c96dd8 100%);
  left: 50%;
  transform: translateX(-50%);
}

.section-title h2:before {
  bottom: 0;
  left: 45%;
}

.section-title h2:after {
  bottom: -5px;
  left: 55%;
}

/*
Flaticon icon font: Flaticon
Creation date: 04/06/2018 14:38
*/
@font-face {
  font-family: "Flaticon";
  src: url("./Flaticon.eot");
  src: url("../fonts/Flaticon.eot?#iefix") format("embedded-opentype"), url("../fonts/Flaticon.woff") format("woff"), url("../fonts/Flaticon.ttf") format("truetype"), url("../fonts/Flaticon.svg#Flaticon") format("svg");
  font-weight: normal;
  font-style: normal;
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  @font-face {
    font-family: "Flaticon";
    src: url("../fonts/Flaticon.svg#Flaticon") format("svg");
  }
}

.fi:before {
  display: inline-block;
  font-family: "Flaticon";
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  line-height: 1;
  text-decoration: inherit;
  text-rendering: optimizeLegibility;
  text-transform: none;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
}

.flaticon-apple-2:before {
  content: "\f100";
}

.flaticon-google-play-1:before {
  content: "\f101";
}

.flaticon-apple-1:before {
  content: "\f102";
}

.flaticon-apple:before {
  content: "\f103";
}

.flaticon-google-play:before {
  content: "\f104";
}

@media (max-width: 576px) {
  .section-title h2 {
    font-size: 24px;
  }
}

/*--------------------------------------------------------------
  ##  Header
  --------------------------------------------------------------*/
.header-inner .site-logo {
  float: left;
  padding: 24px 0;
}

.header-inner .site-logo .main-logo {
  display: block;
}

.header-inner .site-logo .sticky-logo {
  display: none;
}

.site-nav {
  float: right;
  display: flex;
}

.site-nav .site-menu {
  margin: 0;
  list-style: none;
  position: relative;
  width: 600px;
  text-align: right;
}

.site-nav .site-menu li {
  display: inline-block;
  margin-right: 30px;
  padding: 31px 0;
}

.site-nav .site-menu li a {
  color: #fff;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 12px;
}

.site-nav .site-menu li.menu-item-has-children {
  position: relative;
}

.site-nav .site-menu li.menu-item-has-children .sub-menu {
  visibility: hidden;
  opacity: 0;
  margin: 0;
  padding: 0;
  list-style: none;
  background: #fff;
  position: absolute;
  top: 110%;
  left: 0;
  width: 200px;
  text-align: left;
  padding: 20px;
  transition: all 0.3s ease-in-out;
}

.site-nav .site-menu li.menu-item-has-children .sub-menu li {
  display: block;
  padding: 0;
}

.site-nav .site-menu li.menu-item-has-children .sub-menu li a {
  display: block;
  line-height: 30px;
  color: #000;
}

.site-nav .site-menu li.menu-item-has-children .sub-menu li a:hover {
  color: #3224af;
}

.site-nav .site-menu li.menu-item-has-children:hover .sub-menu {
  visibility: visible;
  opacity: 1;
  top: 100%;
}

.site-nav .nav-button {
  margin: 20px 0 25px 20px;
  background: #fff;
  color: #3224af;
  box-shadow: 0px 20px 30px 0px rgba(0, 0, 29, 0.14);
  padding: 9px 15px;
  border-radius: 2px;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 14px;
  height: 44px;
}

.site-nav .nav-button:hover {
  background-image: linear-gradient(-90deg, #3224af 0%, #c96dd8 100%);
  color: #fff;
}

.site-nav .nav-button.nbtn-two {
  background: #3224af;
  border-radius: 30px;
  box-shadow: none;
  color: #fff;
  font-weight: 600;
  font-size: 12px;
  padding: 9px 28px;
  border: 1px solid transparent;
}

.site-nav .nav-button.nbtn-two:hover {
  background: #fff;
  color: #3224af;
}

.header-two .site-nav .site-menu {
  width: 700px;
}

.header-two .site-nav .site-menu li a {
  position: relative;
}

.header-two .site-nav .site-menu li a:before, .header-two .site-nav .site-menu li a:after {
  content: '';
  position: absolute;
  bottom: -5px;
  left: 0;
  width: 100%;
  height: 1px;
  transform: scale3d(0, 1, 1);
  background: #fff;
  transition: transform 0.4s cubic-bezier(0.22, 0.61, 0.36, 1);
}

.header-two .site-nav .site-menu li a:hover:before, .header-two .site-nav .site-menu li a.menu__item--current:before {
  transform: scale3d(1, 1, 1);
  transition-delay: 0s;
}

#magic-line {
  position: absolute;
  bottom: 32px;
  left: 0;
  width: 100px;
  height: 2px;
  background: #fff;
}

.gp-hidden-menu {
  top: -90px !important;
  animation-name: headerSlideDown;
  transition: all 0.3s ease-in-out;
}

.header-main {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 99999;
}

.mob-menu-open {
  position: relative;
  float: right;
  display: block;
  padding: 13px 0;
  text-align: right;
  display: none;
}

.mob-menu-open .bar {
  background: #fff;
  height: 2px;
  width: 30px;
  display: block;
  margin-bottom: 5px;
  transition: all 0.3s ease-in-out;
}

.mob-menu-open .bar:nth-child(2), .mob-menu-open .bar:nth-child(3) {
  width: 25px;
  background: #d286de;
  margin-left: 5px;
}

.mob-menu-open .bar:last-child {
  margin-bottom: 0;
}

.mob-menu-open.active .bar:nth-child(2), .mob-menu-open.active .bar:nth-child(3) {
  opacity: 0;
}

.mob-menu-open.active .bar:nth-child(1) {
  transform: rotate(45deg) translate(7px, 7px);
}

.mob-menu-open.active .bar:nth-child(4) {
  transform: rotate(-45deg) translate(8px, -8px);
}

.gp-header-fixed {
  top: 0;
  left: 0;
  right: 0;
  position: fixed;
  z-index: 999999;
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease-in-out;
  background: #fff;
}

.gp-header-fixed .site-logo {
  padding: 11px 0;
}

.gp-header-fixed .site-menu li {
  padding: 18px 0;
}

.gp-header-fixed .site-menu li a {
  color: #282835;
}

.gp-header-fixed .site-menu li a:hover {
  color: #3224af;
}

.gp-header-fixed .site-menu li a:hover:before, .gp-header-fixed .site-menu li a:hover:after {
  background: #3224af;
}

.gp-header-fixed .site-menu li.menu__item--current a {
  color: #3224af;
}

.gp-header-fixed .site-menu li.menu__item--current a:before, .gp-header-fixed .site-menu li.menu__item--current a:after {
  background: #3224af;
}

.gp-header-fixed .nav-button {
  border: 1px solid #3224af;
  background-size: 200% auto;
  color: #3224af;
  margin: 10px 0 10px 20px;
  box-shadow: none;
}

.gp-header-fixed .nav-button:hover {
  background-image: linear-gradient(to right, #6352fb 0%, #c96dd8 50%, #6352fb 100%);
  color: #fff;
  border-color: transparent;
}

.gp-header-fixed .nav-button.nbtn-two:hover {
  background: transparent;
  color: #3224af;
  border-color: #3224af;
}

.gp-header-fixed .site-logo .main-logo {
  display: none;
}

.gp-header-fixed .site-logo .sticky-logo {
  display: block;
}

.gp-header-fixed #magic-line {
  background: #3224af;
  bottom: 20px;
}

.gp-header-fixed .mob-menu-open .bar {
  background: #282835;
}

.gp-header-fixed .mob-menu-open .bar:nth-child(2), .gp-header-fixed .mob-menu-open .bar:nth-child(3) {
  background: #3224af;
}

.gp-hidden-menu {
  transition: all 0.3s ease-in-out;
}

.header-two .header-main {
  border-bottom: 1px solid rgba(255, 255, 255, 0.102);
}

/* Page Loader */
.page-loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(-90deg, #3224af 0%, #c96dd8 100%);
  z-index: 99999999;
}

@-webkit-keyframes span {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.6);
    transform: scale(0.6);
  }
  60% {
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes span {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.6);
    transform: scale(0.6);
  }
  60% {
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@-webkit-keyframes line {
  0% {
    width: 25vmin;
    opacity: 0;
  }
  10% {
    opacity: 1;
  }
  100% {
    width: 350vmin;
  }
}

@keyframes line {
  0% {
    width: 25vmin;
    opacity: 0;
  }
  10% {
    opacity: 1;
  }
  100% {
    width: 350vmin;
  }
}

@-webkit-keyframes inner {
  from {
    width: 20%;
  }
  to {
    width: 0%;
  }
}

@keyframes inner {
  from {
    width: 10%;
    -webkit-transform: rotate(-360deg);
    transform: rotate(-360deg);
  }
  to {
    width: 0%;
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

#preloader {
  color: white;
  position: absolute;
  text-align: center;
  top: 50%;
  left: 50%;
}

#preloader span {
  font-size: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -30px;
  transform: translate(-50%, -50%);
  width: 50px;
  -webkit-animation: span 1.333s linear infinite;
  animation: span 1.333s linear infinite;
}

.spinner {
  position: absolute;
  top: 50%;
  left: 50%;
}

.spinner .line {
  position: absolute;
  height: 1px;
  -webkit-animation: line 8s linear infinite;
  animation: line 8s linear infinite;
}

.spinner .line::before, .spinner .line::after {
  content: '';
  position: absolute;
  display: block;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: currentColor;
  -webkit-transform-origin: right;
  -ms-transform-origin: right;
  transform-origin: right;
  -webkit-animation: inner 8s linear infinite;
  animation: inner 8s linear infinite;
}

.spinner .line::before {
  -webkit-transform: translateX(-0.5em) rotate(30deg) translateX(0.1em);
  -ms-transform: translateX(-0.5em) rotate(30deg) translateX(0.1em);
  transform: translateX(-0.5em) rotate(30deg) translateX(0.1em);
}

.spinner .line::after {
  -webkit-transform: translateX(-0.5em) rotate(-30deg) translateX(0.1em);
  -ms-transform: translateX(-0.5em) rotate(-30deg) translateX(0.1em);
  transform: translateX(-0.5em) rotate(-30deg) translateX(0.1em);
}

.spinner .line:nth-child(1) {
  -webkit-transform: translate(-50%, -50%) rotate(60deg);
  -ms-transform: translate(-50%, -50%) rotate(60deg);
  transform: translate(-50%, -50%) rotate(60deg);
}

.spinner .line:nth-child(2) {
  -webkit-transform: translate(-50%, -50%) rotate(120deg);
  -ms-transform: translate(-50%, -50%) rotate(120deg);
  transform: translate(-50%, -50%) rotate(120deg);
}

.spinner .line:nth-child(3) {
  -webkit-transform: translate(-50%, -50%) rotate(180deg);
  -ms-transform: translate(-50%, -50%) rotate(180deg);
  transform: translate(-50%, -50%) rotate(180deg);
}

.spinner .line:nth-child(4) {
  -webkit-transform: translate(-50%, -50%) rotate(240deg);
  -ms-transform: translate(-50%, -50%) rotate(240deg);
  transform: translate(-50%, -50%) rotate(240deg);
}

.spinner .line:nth-child(5) {
  -webkit-transform: translate(-50%, -50%) rotate(300deg);
  -ms-transform: translate(-50%, -50%) rotate(300deg);
  transform: translate(-50%, -50%) rotate(300deg);
}

.spinner .line:nth-child(6) {
  -webkit-transform: translate(-50%, -50%) rotate(360deg);
  -ms-transform: translate(-50%, -50%) rotate(360deg);
  transform: translate(-50%, -50%) rotate(360deg);
}

.spinner:nth-child(1) .line,
.spinner:nth-child(1) .line::before,
.spinner:nth-child(1) .line::after {
  -webkit-animation-delay: 0s;
  animation-delay: 0s;
}

.spinner:nth-child(2) .line,
.spinner:nth-child(2) .line::before,
.spinner:nth-child(2) .line::after {
  -webkit-animation-delay: -1.33333s;
  animation-delay: -1.33333s;
}

.spinner:nth-child(3) .line,
.spinner:nth-child(3) .line::before,
.spinner:nth-child(3) .line::after {
  -webkit-animation-delay: -2.66666s;
  animation-delay: -2.66666s;
}

.spinner:nth-child(4) .line,
.spinner:nth-child(4) .line::before,
.spinner:nth-child(4) .line::after {
  -webkit-animation-delay: -4s;
  animation-delay: -4s;
}

.spinner:nth-child(5) .line,
.spinner:nth-child(5) .line::before,
.spinner:nth-child(5) .line::after {
  -webkit-animation-delay: -5.33333s;
  animation-delay: -5.33333s;
}

.spinner:nth-child(6) .line,
.spinner:nth-child(6) .line::before,
.spinner:nth-child(6) .line::after {
  -webkit-animation-delay: -6.66666s;
  animation-delay: -6.66666s;
}

.spinner:nth-child(2n) {
  -webkit-transform: rotate(30deg);
  -ms-transform: rotate(30deg);
  transform: rotate(30deg);
}

/* Mobile Menu */
.mobile-menu-inner {
  max-width: 300px;
  background: linear-gradient(40deg, #4745e4 0%, #18cff5 100%);
  position: fixed;
  width: 100%;
  height: 100vh;
  z-index: 99999;
  top: 50px;
  left: -100%;
  transition: all 0.5s ease-in-out;
  padding: 50px;
  display: none;
}

.mobile-menu-inner .mobile-menu {
  margin: 0;
  list-style: none;
  text-align: center;
  padding: 0;
}

.mobile-menu-inner .mobile-menu li {
  line-height: 30px;
}

.mobile-menu-inner .mobile-menu li a {
  color: #fff;
  font-size: 16px;
}

.slide-menu .mobile-menu-inner {
  left: 0;
}

.mask-overlay {
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  z-index: 111;
}

@media (max-width: 991px) {
  .nav-button {
    display: none;
  }
  .mobile-menu-inner {
    display: block;
  }
  .header-inner .site-logo {
    padding: 9px 0;
    max-width: 90px;
  }
  #magic-line {
    display: none;
  }
  .site-nav {
    display: inherit;
    width: 100%;
  }
  .site-nav .site-menu {
    clear: both;
    width: 100%;
    display: none;
    z-index: 99999;
    transition: max-height 0.2s ease-out;
  }
  .site-nav .site-menu li {
    display: block;
    margin-right: 0;
    text-align: center;
  }
  .site-nav .site-menu li a {
    color: #b5b5b5;
    display: inline-block;
    text-align: center;
    text-decoration: none;
    padding: 0 20px;
    line-height: 40px;
    font-weight: 600;
    text-transform: uppercase;
    font-size: 14px;
  }
  .site-nav .site-menu li a:hover, .site-nav .site-menu li a:focus {
    color: #3224af;
  }
  .js .site-nav .site-menu li a {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.2s ease-out;
  }
  .js .site-nav .site-menu.active li a {
    max-height: 70px;
  }
  .header-main {
    background: rgba(0, 0, 0, 0.9);
    z-index: 9999;
  }
  .header-main.gp-header-fixed {
    background: #fff;
  }
  .mob-menu-open {
    display: block;
  }
  .header-two .site-nav .site-menu li a:before, .header-two .site-nav .site-menu li a:after {
    bottom: 0;
  }
}

/*--------------------------------------------------------------
  ##  Banner
  --------------------------------------------------------------*/
#site-banner {
  height: 100vh;
  padding-top: 210px;
  background-size: cover;
  background-position: center center;
  position: relative;
  overflow: hidden;
  background-image: linear-gradient(40deg, #3224af 0%, #c96dd8 100%);
}

#site-banner .bn-bg img {
  position: absolute;
  top: 0;
  right: 0;
}

.banner-content {
  margin-top: 100px;
}

.banner-content .title {
  font-size: 50px;
  font-weight: 800;
  color: #fff;
  line-height: 55px;
}

.banner-content .sub-title {
  font-size: 16px;
  font-weight: 500;
  color: #fff;
  margin: 40px 0 50px;
}

.banner-content .banner-btn {
  background-image: linear-gradient(to right, #6352fb 0%, #c96dd8 50%, #6352fb 100%);
  background-size: 200% auto;
  color: #fff;
  padding: 17px 24px;
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
  transition: all 0.3s ease-in-out;
  display: inline-block;
  border-radius: 2px;
  box-shadow: 0px 20px 30px 0px rgba(0, 0, 29, 0.14);
}

.banner-content .banner-btn:hover {
  background-position: right center;
}

.banner-mobile {
  text-align: right;
  transform: translateY(160px);
  z-index: 11;
  position: relative;
  animation: float-bob 3s infinite;
}

.header-bg {
  margin: 0;
  padding: 0;
  list-style: none;
}

.header-bg li {
  position: absolute;
  z-index: 0;
}

.header-bg li:nth-child(1) {
  bottom: 10%;
  left: -40px;
}

.header-bg li:nth-child(2) {
  bottom: 30%;
  left: -20%;
}

.header-bg li:nth-child(3) {
  top: 50%;
  left: -20px;
}

.header-bg li:nth-child(4) {
  top: 30%;
  left: -60px;
}

.header-bg li:nth-child(5) {
  top: 10%;
  right: -100px;
}

.header-bg li:nth-child(6) {
  top: 24%;
  right: -20px;
}

.header-bg li:nth-child(7) {
  top: 60%;
  right: -70px;
}

.bottom-shape, .bottom-shape-two {
  position: absolute;
  left: 0;
  bottom: -2px;
  z-index: 1;
}

.bottom-shape-two {
  left: 208px;
  bottom: 20px;
}

/* Banner Two */
#banner-two {
  height: 100vh;
  background-image: linear-gradient(40deg, #4745e4 0%, #18cff5 100%);
  overflow: hidden;
  padding-top: 100px;
}

#banner-two .banner-content .btitle-top {
  font-size: 16px;
  letter-spacing: 6px;
  color: #fff;
  text-transform: uppercase;
  font-weight: 500;
}

#banner-two .banner-content h1 {
  font-size: 44px;
  color: #fff;
  text-transform: uppercase;
  font-weight: 700;
}

#banner-two .banner-content p {
  font-size: 15px;
  color: #fff;
  font-weight: 500;
  margin-bottom: 30px;
}

#banner-two .banner-content .banner-btn-light {
  display: inline-block;
  background: #fff;
  padding: 19px 33px;
  border-radius: 30px;
  color: #3224af;
  font-weight: 600;
  text-transform: uppercase;
  font-size: 12px;
  line-height: 12px;
  box-shadow: 0px 16px 30px 0px rgba(1, 0, 57, 0.14);
}

#banner-two .banner-content .banner-btn-light i {
  margin-left: 3px;
  transition: all 0.3s ease-in-out;
}

#banner-two .banner-content .banner-btn-light:hover {
  background: #3224af;
  color: #fff;
}

#banner-two .banner-content .banner-btn-light:hover i {
  margin-left: 10px;
}

#banner-two .laptop {
  position: absolute;
  left: 50%;
  bottom: 90px;
  transform: translateX(-50%);
  z-index: 1;
}

#banner-two .mobile {
  position: absolute;
  left: 50%;
  bottom: 30px;
  z-index: 9;
  transform: translateX(80%);
}

.scene {
  position: absolute;
  bottom: -40px;
}

.scene.bg-one {
  z-index: 2;
}

.item-bg {
  z-index: 9999;
  left: -100px !important;
  position: relative;
}

.item-bg, .item-bg-two {
  left: 0;
  bottom: 0;
  opacity: 0.302;
}

.item-bg-two {
  left: -100px !important;
  bottom: -40px;
  top: auto !important;
}

/* Bob */
@-webkit-keyframes float-bob {
  0% {
    -webkit-transform: translateY(-20px);
    transform: translateY(-20px);
  }
  50% {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px);
  }
  100% {
    -webkit-transform: translateY(-20px);
    transform: translateY(-20px);
  }
}

@keyframes float-bob {
  0% {
    -webkit-transform: translateY(-20px);
    transform: translateY(-20px);
  }
  50% {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px);
  }
  100% {
    -webkit-transform: translateY(-20px);
    transform: translateY(-20px);
  }
}

@media (max-width: 1440px) {
  .banner-mobile img {
    max-width: 300px;
    float: right;
  }
}

@media (max-width: 1200px) {
  #banner-two {
    padding-top: 65px;
  }
  .banner-content .title {
    font-size: 40px;
    line-height: 50px;
  }
  .banner-content .banner-btn {
    padding: 14px 20px;
  }
  #banner-two .mobile {
    bottom: 50px;
  }
  #banner-two .mobile img {
    width: 180px;
  }
}

@media (max-width: 1024px) {
  #banner-two .banner-content h1 {
    font-size: 35px;
  }
  #banner-two .banner-content p {
    font-size: 14px;
  }
  #banner-two .mobile {
    transform: translateX(60%);
  }
}

@media (min-width: 812px) and (max-width: 992px) {
  #banner-two .banner-content {
    z-index: 999;
    position: relative;
    margin: 0;
  }
  #banner-two .banner-content p {
    display: none;
  }
}

@media (max-width: 992px) {
  #site-banner {
    padding-top: 60px;
  }
  #site-banner .col-md-7, #site-banner .col-md-5 {
    position: static;
  }
  #site-banner .banner-content {
    margin-top: 0;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 60%;
  }
  #site-banner .banner-content .title {
    line-height: 30px;
  }
  .banner-mobile {
    max-width: 200px;
    position: absolute;
    top: 25%;
    right: 50px;
    transform: translateY(-50%);
  }
  .banner-mobile img {
    width: 100%;
    float: right;
  }
  .banner-content .title {
    font-size: 30px;
    line-height: 40px;
  }
  .banner-content .sub-title {
    font-size: 14px;
    margin: 15px 0 30px;
  }
  .banner-content .sub-title br {
    display: none;
  }
  .banner-content .banner-btn {
    padding: 14px 20px;
  }
  #banner-two .banner-content .btitle-top {
    font-size: 14px;
    letter-spacing: 2px;
  }
  #banner-two .banner-content h1 {
    font-size: 28px;
  }
  #banner-two .banner-content .banner-btn-light {
    padding: 15px 28px;
  }
  #banner-two .laptop {
    bottom: 0;
    width: 350px;
  }
  #banner-two .mobile {
    width: 140px;
    bottom: 0;
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  #site-banner {
    padding-top: 0;
  }
  #site-banner .banner-content {
    text-align: center;
  }
  #site-banner .banner-content .title {
    font-size: 35px;
    line-height: 45px;
    margin: 0;
  }
  #site-banner .banner-content .sub-title {
    margin: 15px 0 10px;
  }
  #site-banner .banner-content .banner-btn {
    padding: 12px 20px;
    margin-top: 20px;
    font-size: 12px;
  }
  .banner-mobile {
    display: none;
  }
  #banner-two .banner-content .btitle-top {
    font-size: 14px;
  }
  #banner-two .banner-content h1 {
    font-size: 22px;
  }
  #banner-two .banner-content .banner-btn-light {
    padding: 15px 22px;
  }
  #banner-two .laptop {
    bottom: 20px;
    max-width: 300px;
  }
  #banner-two .mobile {
    bottom: 0;
  }
  #banner-two .mobile img {
    width: 120px;
  }
}

@media (max-width: 768px) {
  #banner-two .banner-content {
    top: 50%;
    transform: translateY(-40%);
    position: absolute;
    left: 0;
    width: 100%;
    padding: 0 10px;
    margin-top: 0;
    z-index: 1111;
  }
  #banner-two .laptop, #banner-two .mobile {
    display: none;
  }
  #site-banner .col-md-7 {
    position: static;
  }
  #site-banner .banner-content {
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    padding: 0 10px;
    transform: translateY(-50%);
    text-align: center;
    margin-top: 0;
  }
  #site-banner .banner-mobile {
    display: none;
  }
}

@media (max-width: 576px) {
  #site-banner {
    padding-top: 0;
  }
  #site-banner .col-md-7 {
    position: static;
  }
  #site-banner .banner-content .btitle-top {
    font-size: 12px;
    letter-spacing: 2px;
  }
  #site-banner .banner-content .title {
    font-size: 22px;
    line-height: 32px;
    margin: 0;
  }
  #site-banner .banner-content .sub-title {
    margin-bottom: 20px;
    max-width: 300px;
    margin: 10px auto 20px;
    font-size: 12px;
    line-height: 20px;
  }
  #site-banner .banner-content .sub-title br {
    display: none;
  }
  #site-banner .banner-content .banner-btn {
    padding: 12px 20px;
    margin-top: 20px;
    font-size: 12px;
    margin-top: 0;
  }
  .banner-mobile {
    display: none;
  }
  #banner-two .banner-content .btitle-top {
    font-size: 12px;
    letter-spacing: 2px;
  }
  #banner-two .banner-content h1 {
    font-size: 22px;
    margin-bottom: 5px;
  }
  #banner-two .banner-content .banner-btn-light {
    padding: 15px 28px;
  }
  #banner-two .laptop, #banner-two .mobile {
    display: none;
  }
}

@media (max-width: 420px) {
  #banner-two {
    height: 70vh;
  }
}

/*--------------------------------------------------------------
  ##  Showcase
  --------------------------------------------------------------*/
#app-showcase {
  padding: 100px 0 70px;
}

.shocase-content {
  margin-top: 90px;
}

.shocase-content .showcase-title {
  font-size: 36px;
  font-weight: 600;
  color: #282835;
  margin-bottom: 20px;
}

.shocase-content p {
  margin-bottom: 20px;
}

.shocase-content .gp-btn {
  margin-top: 20px;
}

.showcase-image img {
  z-index: 22;
  position: relative;
}

.animate-bg {
  margin: 0;
  padding: 0;
  list-style: none;
}

.animate-bg li {
  position: absolute;
  z-index: 0;
}

.animate-bg li:nth-child(1) {
  bottom: -50px;
  left: 6%;
}

.animate-bg li:nth-child(2) {
  top: 54%;
  left: -75px;
}

.animate-bg li:nth-child(3) {
  top: 110px;
  left: 15%;
}

.animate-bg li:nth-child(4) {
  top: 35%;
  right: 18%;
}

.animate-bg li:nth-child(5) {
  bottom: 9%;
  right: 37%;
}

@media (max-width: 768px) {
  #app-showcase {
    padding: 50px 0 30px;
  }
  .showcase-image {
    margin-top: 30px;
  }
  .shocase-content {
    margin-top: 0;
  }
  .shocase-content .showcase-title {
    font-size: 26px;
  }
}

/*--------------------------------------------------------------
  ##  Feature
  --------------------------------------------------------------*/
#feature {
  padding: 100px 0;
  background: #fafcff;
  position: relative;
}

.feature-box-inner {
  margin-top: 100px;
}

.feature-box {
  margin-bottom: 60px;
}

.feature-box.left-icon {
  text-align: right;
}

.feature-box .icon-container {
  margin-bottom: 20px;
}

.feature-box .icon-container i {
  font-size: 40px;
  background-image: linear-gradient(-90deg, #3224af 0%, #c96dd8 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.feature-box .content h3 {
  font-size: 20px;
  font-weight: 600;
  transition: all 0.3s ease-in-out;
  margin-bottom: 15px;
}

.feature-box:hover .content h3 {
  color: #3224af;
}

.feature-image img {
  position: relative;
  z-index: 11;
}

/* Feature Box Two */
#swape {
  padding: 100px 0 70px;
  background: #f9fbff;
}

.feature-box-two {
  margin-bottom: 30px;
}

.feature-box-two .icon-container {
  height: 80px;
  width: 80px;
  border: 1px solid #cfcefe;
  border-radius: 50%;
  text-align: center;
  line-height: 80px;
  margin: 0 auto 30px;
  background-color: #f3f3fe;
  position: relative;
}

.feature-box-two .icon-container:before {
  content: '';
  position: absolute;
  top: 5px;
  right: 5px;
  left: 5px;
  bottom: 5px;
  background: #3224af;
  border-radius: 50%;
  z-index: 0;
  opacity: 1;
  transform: scale(0);
  transition: all 0.3s ease-in-out;
}

.feature-box-two .icon-container i {
  font-size: 32px;
  color: #3224af;
  z-index: 1;
  position: relative;
  line-height: 80px;
}

.feature-box-two .content h3 {
  font-size: 20px;
  color: #282835;
  margin-bottom: 15px;
  margin-top: 0;
  transition: all 0.3s ease-in-out;
}

.feature-box-two:hover .icon-container {
  background-color: rgba(71, 69, 228, 0.1);
  border-color: transparent;
}

.feature-box-two:hover .icon-container:before {
  transform: scale(1);
}

.feature-box-two:hover .icon-container i {
  color: #fff;
}

.feature-box-two:hover .content h3 {
  color: #3224af;
}

.feature-box-two.box-three {
  padding: 30px 30px 0;
  margin: 0;
}

.feature-box-two.box-three p {
  margin-bottom: 30px;
}

/* Feature Three */
#feature-two {
  padding: 100px 0 70px;
}

.b-br {
  border-bottom: 1px solid #eaeff8;
  border-right: 1px solid #eaeff8;
}

.b-r {
  border-right: 1px solid #eaeff8;
}

.bdr-none {
  border-right: 0;
}

@media (min-width: 768px) and (max-width: 992px) {
  .feature-box-inner {
    margin-top: 20px;
  }
  .feature-box {
    margin-bottom: 30px;
  }
  .feature-box .icon-container {
    margin-bottom: 10px;
  }
  .feature-box .content h3 {
    margin-bottom: 10px;
  }
  .feature-box .content p {
    line-height: 22px;
  }
  .br-n {
    border-right: 0 !important;
  }
  .b-r {
    border-right: 1px solid #eaeff8;
  }
  .bb-b {
    border-bottom: 1px solid #eaeff8;
  }
}

@media (max-width: 768px) {
  #feature {
    padding: 50px 0;
  }
  .feature-box-inner {
    margin-top: 30px;
  }
  .feature-box {
    margin-bottom: 30px;
  }
  #feature-two {
    padding: 50px 0 20px;
  }
  #swape {
    padding: 50px 0 20px;
  }
  .sm-brn {
    border-right: 0;
  }
  .sm-br {
    border-bottom: 1px solid #eaeff8;
  }
}

/*--------------------------------------------------------------
  ##  Screenshort
  --------------------------------------------------------------*/
#screenshort {
  padding: 100px 0;
  position: relative;
}

.screenshort-slider {
  padding: 0 20px 30px;
}

.screenshort-thumb {
  position: relative;
  box-shadow: 0px 10px 20px 0px rgba(0, 12, 31, 0.1);
}

.screenshort-thumb img {
  width: 100%;
}

.screenshort-thumb:before {
  position: absolute;
  content: '';
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(50, 36, 175, 0.8);
  visibility: hidden;
  opacity: 0;
  transition: all 0.3s ease-in-out;
}

.screenshort-thumb .popup-btn {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 40px;
  width: 40px;
  border: 1px solid #fff;
  border-radius: 50%;
  text-align: center;
  line-height: 42px;
  color: #fff;
  visibility: hidden;
  opacity: 0;
  transition: all 0.3s ease-in-out;
  font-size: 22px;
}

.screenshort-thumb .popup-btn .ei {
  margin-left: -2px;
}

.screenshort-thumb .popup-btn:hover {
  background: #3224af;
  color: #fff;
  border-color: #3224af;
}

.screenshort-thumb:hover:before, .screenshort-thumb:hover .popup-btn {
  visibility: visible;
  opacity: 1;
}

.screenshort-thumb:hover:before {
  top: 15px;
  bottom: 15px;
  left: 15px;
  right: 15px;
}

.swiper-pagination {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.swiper-pagination-bullet {
  margin: 0 5px;
  position: relative;
  background: #545454;
  transition: all 0.3s ease-in-out;
}

.swiper-pagination-bullet.swiper-pagination-bullet-active {
  background: #3224af;
}

.swiper-pagination-bullet.swiper-pagination-bullet-active:before {
  position: absolute;
  content: '';
  height: 16px;
  width: 16px;
  border: 1px solid #3224af;
  border-radius: 50%;
  left: -4px;
  top: -4px;
}

/* Screenshort Two */
#screenshort-two {
  padding: 100px 0;
  background: #f9fbff;
}

.screenshort-slider-two {
  padding-top: 30px;
  height: 490px;
}

.screenshort-slider-two .swiper-slide img {
  box-shadow: 0px 3px 4px 0px rgba(1, 0, 57, 0.1);
  width: 100%;
  transform: scale(0.9);
  transition: all 0.3s ease-in-out;
}

.screenshort-slider-two .swiper-slide.swiper-slide-active img {
  box-shadow: 0px 20px 30px 0px rgba(1, 0, 57, 0.1);
  transform: scale(1);
}

@media (max-width: 1200px) {
  .screenshort-slider-two {
    height: 420px;
  }
}

@media (max-width: 992px) {
  .screenshort-slider-two {
    height: 320px;
  }
}

@media (max-width: 768px) {
  #screenshort, #screenshort-two {
    padding: 50px 0 60px;
  }
  .screenshort-slider-two {
    height: 390px;
  }
}

@media (max-width: 576px) {
  .screenshort-slider-two {
    height: auto;
  }
  .swiper-pagination {
    display: none;
  }
}

/*--------------------------------------------------------------
  ##  Faq
  --------------------------------------------------------------*/
#faq {
  padding: 100px 0 70px;
  background: #fafcff;
}

#accordion {
  margin-top: 80px;
}

.card, .card-header {
  background: transparent;
  border: 0;
  padding: 10px 0;
}

.card {
  padding: 0;
}

.card .card-header {
  border-bottom: 1px solid #d7e0ed;
  position: relative;
}

.card .card-header .btn {
  color: #81818d;
  text-decoration: none;
  font-size: 18px;
  font-weight: 500;
}

.card .card-header .btn i {
  margin-right: 10px;
}

.card:last-child .card-header {
  border-bottom: transparent;
}

.card.active .card-header {
  border-bottom: transparent;
}

.card.active .card-header .btn {
  color: #3224af;
}

.card-body {
  padding: 10px 10px 10px 45px;
}

.card-body p {
  margin: 0;
}

.card-header > h5:before, .collapsed:before {
  display: block;
  position: absolute;
  font-size: 25px;
  top: 50%;
  right: 0;
  z-index: 9;
  font-family: 'ElegantIcons';
  transition: all 0.5s;
  transform: translateY(-50%);
  font-size: 16px;
}

.card-header > h5:before {
  content: "\4b";
  color: #3224af;
}

.collapsed:before {
  content: "\4c";
}

.faq-thumb img {
  position: relative;
  z-index: 11;
}

@media (max-width: 992px) {
  #accordion {
    margin-top: 0;
  }
}

@media (max-width: 768px) {
  #accordion {
    margin-bottom: 30px;
  }
  #faq {
    padding: 50px 0 30px;
    background: #fafcff;
  }
}

/*--------------------------------------------------------------
  ##  Pricing Table
  --------------------------------------------------------------*/
#pricing {
  padding: 100px 0 70px;
}

#pricing .animate-bg li:nth-child(1) {
  left: 6%;
  bottom: 30%;
}

#pricing .animate-bg li:nth-child(4) {
  top: 60%;
}

#pricing .animate-bg li:nth-child(6) {
  top: 50%;
  left: 16%;
}

.procing-table {
  text-align: center;
  position: relative;
  padding: 45px 40px 60px;
  border: 1px solid #f0f0fa;
  background: #fafcff;
  transition: all 0.3s ease-in-out;
  margin-bottom: 30px;
}

.procing-table.featured:before {
  content: '';
  position: absolute;
  background-image: url(../../media/pricing/1.png);
  background-repeat: no-repeat;
  background-position: right top;
  width: 100%;
  height: 100%;
  right: 0;
  top: 0;
}

.procing-table .price-header {
  border-bottom: 1px solid #e8e8f0;
  padding-bottom: 10px;
  margin-bottom: 20px;
}

.procing-table .price-header .price-title {
  font-size: 20px;
  font-weight: 500;
  margin-top: 0;
  margin-bottom: 15px;
}

.procing-table .price-header .sub-title {
  margin-bottom: 10px;
  line-height: 14px;
}

.procing-table .price-period {
  margin-bottom: 25px;
}

.procing-table .price-period .price {
  font-size: 50px;
  margin: 0;
  transition: all 0.3s ease-in-out;
}

.procing-table .price-period .price .period {
  font-size: 14px;
  color: #8b8b99;
  font-family: "Montserrat", sans-serif;
}

.procing-table .price-feature {
  margin: 0 0 40px;
  padding: 0;
  list-style: none;
  position: relative;
}

.procing-table .price-feature li {
  font-size: 16px;
  line-height: 40px;
  color: #8b8b99;
  font-weight: 300;
  font-family: "Montserrat", sans-serif;
}

.procing-table .price-feature li i {
  margin-right: 20px;
  color: #3224af;
}

.procing-table .price-feature li i.ei-icon_close {
  display: inline-block;
  color: #fa4b4b;
}

.procing-table .buy-btn {
  padding: 11px 25px;
  border: 1px solid #3224af;
  color: #3224af;
  display: inline-block;
  font-size: 12px;
  font-weight: 500;
  text-transform: uppercase;
  font-family: "Montserrat", sans-serif;
  position: relative;
  transition: all 0.3s ease-in-out;
  border-radius: 2px;
  backface-visibility: hidden;
}

.procing-table .buy-btn:hover {
  color: #fff;
  background-image: linear-gradient(-90deg, #3224af 0%, #c96dd8 100%);
}

.procing-table.price-two.featured:before {
  background-image: url(../../media/pricing/2.png);
}

.procing-table.price-two.featured:hover .price-period .price {
  color: #3224af;
}

.procing-table.price-two .price-feature li {
  font-size: 15px;
}

.procing-table.price-two .price-feature {
  margin-bottom: 35px;
}

.procing-table.price-two .buy-btn {
  border-radius: 30px;
  border-color: #cfcefe;
  color: #3224af;
  background: #f3f3fe;
}

.procing-table.price-two .buy-btn i {
  margin-left: 3px;
  transition: all 0.3s ease-in-out;
}

.procing-table.price-two .buy-btn:hover {
  background: #3224af;
  color: #fff;
}

.procing-table.price-two .buy-btn:hover i {
  margin-left: 9px;
}

.procing-table:hover {
  box-shadow: 0px 30px 50px 0px rgba(0, 0, 29, 0.1);
}

#pricing-two {
  padding: 100px 0 70px;
}

@media (max-width: 768px) {
  #pricing, #pricing-two {
    padding: 50px 0 20px;
  }
  .procing-table.featured:before {
    background-size: cover;
    background-position: center center;
  }
}

/*--------------------------------------------------------------
  ##  Download
  --------------------------------------------------------------*/
#download {
  background-image: linear-gradient(45deg, #c96dd8 0%, #3224af 100%);
  padding: 150px 0;
  position: relative;
  overflow: hidden;
}

.store-btns .store-btn {
  margin-right: 20px;
  transition: all 0.3s ease-in-out;
  display: inline-block;
  border: 1px solid #fff;
  color: #fff;
  padding: 7px 20px;
  font-size: 16px;
  border-radius: 3px;
  font-weight: 600;
  text-align: left;
}

.store-btns .store-btn span {
  display: block;
  font-size: 12px;
  font-weight: 400;
}

.store-btns .store-btn .btn-text {
  margin-left: 40px;
  line-height: 21px;
}

.store-btns .store-btn i {
  float: left;
  font-size: 30px;
  line-height: 42px;
}

.store-btns .store-btn:hover {
  background: #fff;
  border-color: #fff;
  color: #5c6777;
}

.download-content {
  margin-top: 30px;
  position: relative;
  z-index: 1;
}

.download-content .download-title {
  font-size: 30px;
  font-weight: 700;
  color: #fff;
  margin-bottom: 20px;
}

.download-content p {
  color: #fff;
  margin-bottom: 50px;
}

.wave-bg .wave {
  position: absolute;
  left: 0;
  bottom: 0;
  height: 250px;
  z-index: 0;
}

.download-thumb {
  position: relative;
  z-index: 1;
}

.download-thumb .p1, .download-thumb .p2 {
  position: absolute;
}

.download-thumb .p1 {
  z-index: 2;
}

.download-thumb .p2 {
  right: -75px;
  top: 80px;
}

/* Download Two */
#download-two {
  padding: 140px 0 220px;
  background: linear-gradient(40deg, #4745e4 0%, #18cff5 100%);
  position: relative;
  overflow: hidden;
}

#download-two:before {
  position: absolute;
  content: '';
  width: 100%;
  height: 100%;
  background-image: linear-gradient(40deg, #4745e4 0%, #18cff5 100%);
  opacity: 0.8;
  left: 0;
  top: 0;
  z-index: 2;
}

#download-two .download-content {
  margin-top: 0;
  position: relative;
  z-index: 22;
}

#download-two canvas {
  z-index: 333;
}

.bg-animate-mob img {
  position: absolute;
}

.bg-animate-mob .mbg1 {
  top: -230px;
  left: -100px;
}

.bg-animate-mob .mbg2 {
  left: 16%;
  top: -59%;
}

.bg-animate-mob .mbg3 {
  left: 2%;
  top: 40%;
}

.bg-animate-mob .mbg4 {
  right: -14%;
  top: -25%;
}

.bg-animate-mob .mbg5 {
  right: 12%;
  top: 10%;
}

@media (max-width: 992px) {
  #download-two {
    padding: 100px 0 190px;
  }
}

@media (max-width: 768px) {
  #download {
    padding-top: 50px;
  }
  .download-content {
    margin-top: 0;
  }
  #download-two {
    padding: 80px 0 160px;
  }
}

@media (max-width: 576px) {
  .store-btns .store-btn {
    margin-bottom: 10px;
  }
  .card .card-header .btn {
    font-size: 14px;
  }
  .card-body p {
    font-size: 14px;
  }
}

/*--------------------------------------------------------------
  ##  Testimonial
  --------------------------------------------------------------*/
#testimonial {
  padding: 100px 0;
  background: #f3f5f9;
}

.testi-slider {
  padding: 20px 15px 40px;
}

.testimonial-content {
  background: #fff;
  padding: 40px;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 29, 0.08);
  transition: all 0.3s ease-in-out;
}

.testimonial-content .content {
  position: relative;
}

.testimonial-content .content .quote {
  position: absolute;
  right: 0;
  top: -10px;
  z-index: 0;
}

.testimonial-content .content p {
  position: relative;
  z-index: 1;
  font-size: 16px;
}

.testimonial-content .testi-author {
  display: flex;
  align-items: center;
}

.testimonial-content .testi-author .testi-avatar {
  height: 65px;
  width: 65px;
  border-radius: 50%;
  overflow: hidden;
  border: 5px solid #f9f0fb;
}

.testimonial-content .testi-author .user-details {
  margin-left: 30px;
}

.testimonial-content .testi-author .user-details .name {
  font-size: 16px;
  font-weight: 600;
  margin-top: 0;
}

.testimonial-content .testi-author .user-details .position {
  font-size: 16px;
  color: #8b8b99;
}

.testimonial-content:hover {
  box-shadow: 0px 20px 40px 0px rgba(0, 0, 29, 0.08);
}

.ratings {
  margin: 0 0 15px;
  padding: 0;
  list-style: none;
  text-align: center;
}

.ratings li {
  display: inline-block;
  margin-right: 5px;
  color: #fac50f;
}

/* Testimonial Two */
#testimonial-two {
  background: #f9fbff;
  padding: 40px 0 80px;
}

.testimonial-two .slider-nav .slick-slide {
  padding: 20px 0;
  height: 110px;
}

.testimonial-two .slider-nav .slick-slide.slick-current .testi-avatar {
  height: 80px;
  width: 80px;
  transform: translate(-10px, -10px);
  opacity: 1;
}

.testimonial-content-two {
  box-shadow: 0px 20px 50px 0px rgba(1, 0, 57, 0.1);
  max-width: 810px;
  margin: 45px auto;
  padding: 30px 50px 40px;
  border-radius: 10px;
}

.testimonial-content-two .quote {
  margin-bottom: 20px;
}

.testimonial-content-two .quote img {
  margin: 0 auto 20px;
}

.testimonial-content-two p {
  font-size: 15px;
}

.testimonial-content-two .testi-author .user-details .name, .testimonial-content-two .testi-author .user-details .position {
  display: inline-block;
}

.testimonial-content-two .testi-author .user-details .name {
  font-size: 16px;
  font-weight: 600;
  color: #282835;
}

.testimonial-content-two .testi-author .user-details .position {
  font-size: 14px;
  color: #b0b0bd;
  font-weight: 300;
  margin-left: 15px;
  position: relative;
}

.testimonial-content-two .testi-author .user-details .position:before {
  content: '/';
  position: absolute;
  left: -10px;
  top: 0;
}

.slider-nav {
  width: 350px;
  margin: 0 auto;
}

.slider-nav .testi-avatar {
  height: 60px;
  width: 60px;
  border-radius: 50%;
  transition: all 0.3s ease-in-out;
  opacity: 0.6;
  cursor: pointer;
}

.slider-nav .testi-avatar img {
  border-radius: 50%;
  width: 100%;
}

.PrevArrow, .NextArrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 6666;
  font-size: 30px;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
}

.PrevArrow:hover, .NextArrow:hover {
  color: #3224af;
}

.PrevArrow {
  left: 0;
}

.NextArrow {
  right: 0;
}

@media (min-width: 600px) and (max-width: 768px) {
  .testimonial-content {
    padding: 15px;
  }
  .testimonial-content .testi-author .user-details {
    margin-left: 10px;
  }
  .testimonial-content .testi-author .user-details .name {
    font-size: 14px;
    margin-bottom: 0px;
  }
  .testimonial-content .testi-author .user-details .position {
    font-size: 12px;
  }
}

@media (max-width: 768px) {
  #testimonial-two {
    padding: 10px 0 30px;
  }
  #testimonial {
    padding: 50px 0;
  }
}

@media (max-width: 576px) {
  .slider-nav {
    width: 90%;
  }
  .slider-nav .testi-avatar {
    height: 50px;
  }
  .slick-arrow {
    display: none !important;
  }
  .testimonial-content {
    padding: 20px;
  }
  .testimonial-content .content p {
    font-size: 14px;
  }
}

/*--------------------------------------------------------------
  ##  News
  --------------------------------------------------------------*/
#news {
  padding: 100px 0 70px;
}

.news-grid {
  margin-bottom: 30px;
}

.news-grid .features-image {
  margin-bottom: 20px;
  overflow: hidden;
  position: relative;
}

.news-grid .features-image a:before {
  position: absolute;
  content: '';
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.4);
  z-index: 222;
  opacity: 0;
  transition: all 0.3s ease-in-out;
}

.news-grid .features-image img {
  transition: all 0.3s ease-in-out;
  z-index: 11;
}

.news-grid .entry-header .post-meta {
  margin: 0 0 10px;
  padding: 0;
  list-style: none;
}

.news-grid .entry-header .post-meta li {
  display: inline-block;
  color: #99a1af;
  position: relative;
  margin-right: 15px;
}

.news-grid .entry-header .post-meta li:after {
  position: absolute;
  content: '';
  height: 12px;
  width: 1px;
  right: -10px;
  top: 7px;
  background: #99a1af;
}

.news-grid .entry-header .post-meta li:last-child:after {
  display: none;
}

.news-grid .entry-header .post-meta li a {
  color: #99a1af;
  font-weight: 500;
  font-size: 14px;
}

.news-grid .entry-header .post-meta li a:hover {
  color: #3224af;
}

.news-grid .entry-header .entry-title {
  font-size: 20px;
  line-height: 30px;
  font-weight: 500;
  font-family: "Montserrat", sans-serif;
  margin-bottom: 15px;
}

.news-grid .entry-header .entry-title a {
  color: #282835;
}

.news-grid .entry-header .entry-title a:hover {
  color: #3224af;
}

.news-grid .entry-header .read-btn {
  text-transform: uppercase;
  font-size: 12px;
  color: #8b8b99;
  font-weight: 500;
}

.news-grid .entry-header .read-btn i {
  margin-left: 3px;
  transition: all 0.23s ease-in-out;
}

.news-grid .entry-header .read-btn:hover {
  color: #3224af;
}

.news-grid .entry-header .read-btn:hover i {
  margin-left: 5px;
}

.news-grid:hover .features-image a:before {
  opacity: 1;
}

.news-grid:hover .features-image img {
  transform: scale(1.05);
}

#blog-post {
  padding: 100px 0;
}

.post {
  margin-bottom: 40px;
}

.post .features-image {
  position: relative;
}

.post .features-image img {
  max-width: 100%;
}

.post .features-image .posttype {
  position: absolute;
  top: 30px;
  left: 20px;
  height: 60px;
  width: 60px;
  font-size: 24px;
  color: #fff;
  text-align: center;
  line-height: 60px;
  background: rgba(27, 29, 34, 0.702);
  border-radius: 3px;
}

.post .post-content {
  padding: 20px 0 40px 40px;
  border-bottom: 1px solid #e3e6ed;
}

.post .post-title {
  font-size: 30px;
  line-height: 40px;
}

.post .post-title a {
  color: #282835;
}

.post .post-title a:hover {
  color: #3224af;
}

.post .read-btn {
  font-size: 12px;
  color: #43464c;
  position: relative;
  text-transform: uppercase;
  font-weight: 600;
}

.post .read-btn i {
  transition: all 0.3s ease-in-out;
}

.post .read-btn:after {
  position: absolute;
  content: '';
  width: 77px;
  height: 1px;
  background: #43464c;
  left: 0;
  bottom: 0;
}

.post .read-btn:hover {
  color: #3224af;
}

.post .read-btn:hover:after {
  background: #3224af;
}

.post .read-btn:hover i {
  margin-left: 3px;
}

.post.link {
  background: #f9f9f9;
}

.post.link .post-content {
  border-bottom: 0;
  padding: 60px 50px;
}

.post.link .post-content .link-icon {
  position: absolute;
  right: 40px;
  font-size: 40px;
}

.post-meta {
  margin-left: 0;
  margin-bottom: 20px;
  padding: 0;
  list-style: none;
}

.post-meta li {
  display: inline-block;
  margin-right: 20px;
  position: relative;
  color: #656971;
}

.post-meta li:after {
  position: absolute;
  content: '';
  height: 12px;
  width: 2px;
  top: 7px;
  right: -15px;
  background: #656971;
}

.post-meta li a {
  color: #656971;
  font-size: 14px;
}

.post-meta li a:hover {
  color: #3224af;
}

.post-meta li:last-child:after {
  display: none;
}

.post-pagination {
  margin: 0;
  padding: 0;
  list-style: none;
}

.post-pagination li {
  display: inline-block;
}

.post-pagination li a {
  height: 44px;
  width: 44px;
  text-align: center;
  line-height: 44px;
  font-size: 14px;
  color: #43464c;
  border: 1px solid #cbd0dd;
  display: inline-block;
  border-radius: 50%;
  margin-right: 10px;
  transition: all 0.3s ease-in-out;
}

.post-pagination li a:hover, .post-pagination li a.active {
  background: #3224af;
  border-color: #3224af;
  color: #fff;
}

.youtube-banner {
  height: 100%;
  overflow: hidden;
}

.youtube-wrapper {
  position: relative;
  padding-bottom: 59.7%;
  height: 0;
  overflow: hidden;
  max-width: 100%;
  background-color: #191a1c;
  cursor: pointer;
}

.youtube-wrapper .posttype {
  z-index: 2;
}

.youtube-wrapper:before {
  content: "\45";
  font-family: 'ElegantIcons';
  font-size: 50px;
  position: absolute;
  top: 50%;
  left: 50%;
  color: #fff;
  z-index: 2;
  cursor: pointer;
  visibility: visible;
  transform: translate3d(-50%, -50%, 0) scale(1);
  -moz-transform: translate3d(-50%, -50%, 0);
  -webkit-transform: translate3d(-50%, -50%, 0);
  transition: transform .2s, opacity .2s;
  -moz-transition: transform .2s, opacity .2s;
  -webkit-transition: transform .2s, opacity .2s;
  height: 80px;
  width: 80px;
  text-align: center;
  line-height: 80px;
  border: 1px solid rgba(255, 255, 255, 0.502);
  background: rgba(255, 255, 255, 0.2);
  border-radius: 50%;
}

.youtube-wrapper.reveal:after, .youtube-wrapper.reveal:before, .youtube-wrapper.reveal .blog-content {
  opacity: 0;
  visibility: hidden;
  transition: visibility .4s, opacity .4s;
}

.youtube-wrapper.reveal iframe {
  visibility: visible;
}

.youtube-wrapper.reveal .posttype {
  opacity: 0;
}

.youtube-wrapper iframe, .youtube-wrapper object, .youtube-wrapper embed {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  border: none;
  visibility: hidden;
}

.Ellipse {
  border-width: 2px;
  border-color: white;
  border-style: solid;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.2);
  opacity: 0.502;
  position: absolute;
  left: 700px;
  top: 1610px;
  width: 76px;
  height: 76px;
  z-index: 210;
}

.youtube-wrapper .youtube-poster {
  height: 440px;
  background-position: center;
  background-size: cover;
}

/*
*
* Blog Single
*
*/
#blog-post-single {
  padding: 100px 0;
}

.post.post-single {
  margin-bottom: 40px;
}

.post.post-single .post-content {
  padding: 0 0 20px 0;
  margin-top: 30px;
}

.post.post-single .post-title {
  margin-bottom: 20px;
}

.post.post-single .post-meta {
  padding-bottom: 20px;
  border-bottom: 1px solid #e3e6ed;
}

.post.post-single h3 {
  font-size: 24px;
  font-weight: 500;
  color: #1b1d22;
  line-height: 1.5;
  font-style: italic;
}

.post-inner-image {
  display: flex;
  flex-wrap: wrap;
  margin: 20px 0;
}

.post-inner-image .image-wrap {
  width: calc(50% - 10px);
  margin-right: 10px;
}

.post-footer {
  display: flex;
  flex-wrap: wrap;
  padding-top: 15px;
}

.post-footer .post-share-inner {
  width: 75%;
}

.post-footer .post-navigation {
  width: 25%;
}

.post-footer .post-share-inner {
  display: flex;
}

.post-footer .post-share {
  margin: 0 0 0 10px;
  padding: 0;
  list-style: none;
}

.post-footer .post-share li {
  display: inline-block;
  margin-right: 10px;
}

.post-footer .post-share li a {
  color: #9b9da3;
  font-size: 16px;
}

.post-footer .post-share li a:hover {
  color: #3224af;
}

.post-footer .post-navigation a {
  font-size: 12px;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  color: #43464c;
  text-transform: uppercase;
}

.post-footer .post-navigation a i {
  transition: all .3s ease-in-out;
}

.post-footer .post-navigation a:hover {
  color: #3224af;
}

.post-footer .post-navigation a:hover i {
  margin-left: 3px;
}

/*
*
* Author
*
*/
.gp_appxbe_post_author_box {
  padding: 10px;
  border: 1px solid #eee;
  display: flex;
  flex-wrap: wrap;
  border-radius: 5px;
  margin-bottom: 60px;
}

.gp_appxbe_post_author_box .profile_image {
  width: 100px;
  margin-right: 20px;
  border-radius: 50%;
}

.gp_appxbe_post_author_box .profile_image img {
  border-radius: 50%;
}

.gp_appxbe_post_author_box .profile_content {
  flex: 1;
}

.gp_appxbe_post_author_box .profile_name {
  font-size: 18px;
}

.gp_appxbe_post_author_box .profile_bio {
  margin-bottom: 10px;
}

.gp_appxbe_post_author_box .gp-author-social-link {
  margin: 0;
  padding: 0;
  list-style: none;
}

.gp_appxbe_post_author_box .gp-author-social-link li {
  display: inline-block;
  margin-right: 10px;
}

.gp_appxbe_post_author_box .gp-author-social-link li a {
  display: block;
  font-size: 16px;
  color: #43464c;
}

.gp_appxbe_post_author_box .gp-author-social-link li a:hover {
  color: #3224af;
}

/*
*
* Comments
*
*/
#comments {
  margin-top: 30px;
}

#comments .comment-reply-title {
  font-size: 22px;
  margin-bottom: 20px;
  padding-bottom: 10px;
  color: #1b1d22;
  font-weight: 600;
  position: relative;
}

#comments .comment-reply-title:after {
  position: absolute;
  content: '';
  left: 0;
  bottom: 0;
  height: 2px;
  width: 80px;
  background-image: linear-gradient(40deg, #4745e4 0%, #18cff5 100%);
}

.comment-list {
  margin: 0;
  padding: 0;
  list-style: none;
}

.comment-list .comment .comment-body {
  padding-bottom: 30px;
  position: relative;
  display: flex;
  flex-wrap: wrap;
}

.comment-list .comment .comment-body .comment-author {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  margin-right: 20px;
}

.comment-list .comment .comment-body .comment-author img {
  border-radius: 50%;
}

.comment-list .comment .comment-body .comment-content {
  flex: 1;
  border-bottom: 1px solid #e3e6ed;
}

.comment-list .comment .comment-body .comment-content .fn {
  font-size: 18px;
  font-weight: 500;
  color: #1b1d22;
  display: inline-block;
}

.comment-list .comment .comment-body .comment-content p {
  margin-bottom: 30px;
  font-family: "Poppins", sans-serif;
  color: #656971;
  font-size: 14px;
}

.comment-list .comment .comment-body .comment-content .comment-metadata {
  display: inline-block;
  position: relative;
  margin-left: 20px;
}

.comment-list .comment .comment-body .comment-content .comment-metadata a {
  color: #656971;
  font-size: 15px;
  font-family: "Poppins", sans-serif;
}

.comment-list .comment .comment-body .comment-content .comment-metadata:before {
  position: absolute;
  content: '';
  left: -12px;
  top: 6px;
  height: 12px;
  width: 1px;
  background: #919499;
}

.comment-list .comment .comment-body .reply {
  position: absolute;
  right: 0;
  bottom: 35px;
}

.comment-list .comment .comment-body .reply a {
  font-size: 12px;
  font-weight: 600;
  color: #656971;
  text-transform: uppercase;
}

.comment-list .comment .comment-body .reply a i {
  margin-right: 5px;
}

.comment-list .comment .comment-body .reply a:hover {
  color: #3224af;
}

.comment-list .children {
  margin: 0;
  list-style: none;
}

.comment-form .comment-form-comment {
  width: 100%;
}

.comment-form .comment-form-author, .comment-form .comment-form-email {
  width: 50%;
  float: left;
}

.comment-form .comment-form-author {
  padding-right: 15px;
}

.comment-form .comment-form-email {
  padding-left: 15px;
}

.comment-form .comment-form-url {
  width: 100%;
  margin-bottom: 30px;
}

.comment-form input {
  height: 60px;
}

.comment-form textarea {
  height: 200px;
}

.comment-form textarea, .comment-form input {
  width: 100%;
  background: #f9f9f9;
  border: 1px solid #e9edf6;
  outline: 0;
  transition: all 0.3s ease-in-out;
  padding: 10px 15px;
  border-radius: 3px;
  font-family: "Poppins", sans-serif;
}

.comment-form textarea::placeholder, .comment-form input::placeholder {
  color: #656971;
}

.comment-form textarea:hover, .comment-form textarea:focus, .comment-form input:hover, .comment-form input:focus {
  outline: 0;
  box-shadow: none;
  background: #fff;
  box-shadow: -10px 17.321px 40px 0px rgba(14, 0, 40, 0.2);
}

.form-submit #submit {
  background: #3224af;
  padding: 14px 40px;
  text-align: center;
  border: 0;
  color: #fff;
  border-radius: 30px;
  height: auto;
  font-family: "Poppins", sans-serif;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  letter-spacing: 2px;
}

.form-submit #submit i {
  transition: all 0.3s ease-in-out;
}

.form-submit #submit:hover {
  box-shadow: none;
  background: #1b1d22;
  color: #fff;
}

.form-submit #submit:hover i {
  margin-left: 3px;
}

@media (max-width: 992px) {
  .sidebar {
    margin-top: 30px;
  }
}

@media (max-width: 768px) {
  #news {
    padding: 50px 0 20px;
  }
  .post .post-title {
    font-size: 24px;
    line-height: 30px;
  }
}

@media (max-width: 576px) {
  #news {
    padding: 50px 0 20px;
  }
  .post .post-content {
    padding: 20px;
  }
  .post .post-title {
    font-size: 20px;
    line-height: 28px;
  }
  .post-meta li {
    margin-right: 15px;
  }
  .post-meta li:after {
    right: -10px;
  }
  .post-meta li a {
    font-size: 12px;
  }
  .comment-form .comment-form-author, .comment-form .comment-form-email {
    width: 100%;
    padding: 0;
  }
  .post.link .post-content {
    padding: 30px;
  }
}

/*--------------------------------------------------------------
  ##  Team
  --------------------------------------------------------------*/
#team {
  padding: 100px 0 70px;
}

#team .more-btn {
  display: block;
  text-align: center;
  margin-top: 50px;
  font-size: 14px;
  font-weight: 500;
  color: #b0b0bd;
}

#team .more-btn i {
  margin-left: 3px;
  transition: all 0.3s ease-in-out;
}

#team .more-btn:hover {
  color: #3224af;
}

#team .more-btn:hover i {
  margin-left: 7px;
}

.team-member {
  margin-bottom: 30px;
}

.team-member .member-avatar {
  position: relative;
}

.team-member .member-avatar:before {
  position: absolute;
  content: '';
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-image: linear-gradient(40deg, #4745e4 0%, #18cff5 100%);
  opacity: 0;
  z-index: 0;
  transition: all 0.6s cubic-bezier(0.23, 1, 0.32, 1);
  transform: scale(0.9);
}

.team-member .member-avatar .team-details {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  visibility: hidden;
}

.team-member .member-avatar .team-details .content {
  position: absolute;
  z-index: 222;
  text-align: center;
  padding: 20px;
  top: 50%;
  transform: translateY(-50%);
}

.team-member .member-avatar .team-details .content p {
  color: #fff;
  margin-bottom: 20px;
  opacity: 0;
  transition: all 0.5s ease-in-out;
}

.team-member .member-avatar .team-details .content .member-profile {
  margin: 0;
  padding: 0;
  list-style: none;
}

.team-member .member-avatar .team-details .content .member-profile li {
  display: inline-block;
  transform: scale(0);
  transition: all 0.3s ease-in-out;
  transition: all 0.45s cubic-bezier(0.23, 0.88, 0.34, 0.99);
}

.team-member .member-avatar .team-details .content .member-profile li a {
  height: 40px;
  width: 40px;
  font-size: 14px;
  text-align: center;
  line-height: 40px;
  color: #fff;
  border-radius: 50%;
  border: 1px solid #fff;
  display: inline-block;
  margin: 0 5px;
  transition: all 0.3s ease-in-out;
  overflow: hidden;
}

.team-member .member-avatar .team-details .content .member-profile li a:hover {
  background: #fff;
  color: #3224af;
}

.team-member .member-bio {
  margin-top: 20px;
  text-align: center;
}

.team-member .member-bio .name {
  font-size: 16px;
  font-weight: 600;
  margin-top: 0;
  color: #282835;
  margin-bottom: 0;
}

.team-member .member-bio .position {
  color: #8b8b99;
  font-size: 14px;
}

.team-member:hover .member-avatar:before {
  opacity: 0.9;
  transform: scale(1);
}

.team-member:hover .member-avatar .team-details {
  opacity: 1;
  visibility: visible;
}

.team-member:hover .member-avatar .team-details .content p {
  opacity: 1;
}

.team-member:hover .member-avatar .team-details .content .member-profile li {
  transform: scale(1);
}

.team-member:hover .member-avatar .team-details .content .member-profile li:nth-child(1) {
  transition-delay: .07692s;
}

.team-member:hover .member-avatar .team-details .content .member-profile li:nth-child(2) {
  transition-delay: .15692s;
}

.team-member:hover .member-avatar .team-details .content .member-profile li:nth-child(3) {
  transition-delay: .21692s;
}

@media (max-width: 768px) {
  #team {
    padding: 50px 0;
  }
  #team .more-btn {
    margin-top: 0;
  }
}

/*--------------------------------------------------------------
  ##  Newsletter
  --------------------------------------------------------------*/
#newsletter {
  padding: 100px 0;
}

.newsletter-form {
  max-width: 765px;
  background: #fff;
  border: 1px solid #f0f0fa;
  padding: 10px 10px 10px 35px;
  margin: 0 auto;
}

.newsletter-form i {
  font-size: 27px;
  color: #ecd3f0;
  line-height: 54px;
}

.newsletter-form .fa-spin {
  display: none;
}

.newsletter-form input {
  border: 0;
  background: transparent;
  outline: 0;
  color: #282835;
  font-size: 16px;
  font-weight: 500;
  height: 60px;
  width: 70%;
  display: inline-block;
}

.newsletter-form input::placeholder {
  color: #bbbbc9;
}

.newsletter-form input:focus {
  box-shadow: none;
  border: 0;
}

.newsletter-form #newsletter-submit {
  padding: 20px 38px;
  background-image: linear-gradient(to right, #6352fb 0%, #c96dd8 50%, #6352fb 100%);
  background-size: 200% auto;
  text-transform: uppercase;
  color: #fff;
  font-size: 12px;
  letter-spacing: 3px;
  outline: 0;
  border: 0;
  margin-top: -5px;
  display: inline-block;
  transition: all 0.3s ease-in-out;
}

.newsletter-form #newsletter-submit:hover, .newsletter-form #newsletter-submit:focus {
  box-shadow: none;
  background-position: right center;
}

.form-result {
  display: none;
  width: 90%;
  margin: 10px auto;
}

@media (max-width: 768px) {
  #newsletter {
    padding: 50px 0;
  }
}

@media (max-width: 576px) {
  .newsletter-form {
    padding: 10px;
  }
  .newsletter-form i {
    line-height: 36px;
    font-size: 20px;
  }
  .newsletter-form input {
    height: 40px;
    font-size: 14px;
  }
  .newsletter-form #newsletter-submit {
    padding: 13px 10px;
    height: 40px;
    letter-spacing: 0;
  }
}

/*--------------------------------------------------------------
  ##  Prosses
  --------------------------------------------------------------*/
#prosess {
  padding: 100px 0;
  overflow: hidden;
}

.prosess-tabs {
  border-bottom: 1px solid #d7dde9;
  padding-bottom: 10px;
}

.prosess-tabs li {
  margin-right: 20px;
}

.prosess-tabs li a {
  color: #6b727f;
  padding: 0;
  font-size: 14px;
  font-weight: 500;
  position: relative;
}

.prosess-tabs li a:after {
  position: absolute;
  content: '';
  background: #3224af;
  width: 0;
  height: 3px;
  left: 0;
  bottom: -12px;
  transition: all 0.3s ease-in-out;
}

.prosess-tabs li a.active {
  color: #3224af;
}

.prosess-tabs li a.active:after {
  width: 100%;
}

.prosess-tabs-inner {
  margin-top: 40px;
}

.prosess-tabs-inner .title {
  font-size: 30px;
  margin-bottom: 25px;
  margin-top: 0;
  font-weight: 700;
}

.prosess-tabs-inner .tab-content .tab-pane {
  padding: 20px 0;
}

.prosess-tabs-inner .tab-content .tab-pane p {
  margin-bottom: 20px;
}

.prosess-tabs-inner .tab-content .tab-pane.active {
  animation: fadeIn .75s ease forwards;
}

.prosess-tabs-inner .tab-content .tab-pane .read-btn {
  padding: 12px 25px;
  background: #3224af;
  color: #fff;
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 600;
  border-radius: 30px;
  display: inline-block;
}

.prosess-tabs-inner .tab-content .tab-pane .read-btn i {
  margin-left: 3px;
  transition: all 0.3s ease-in-out;
}

.prosess-tabs-inner .tab-content .tab-pane .read-btn:hover {
  background: #333;
}

.prosess-tabs-inner .tab-content .tab-pane .read-btn:hover i {
  margin-left: 7px;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translate3d(0, 20px, 0);
    -webkit-transform: translate3d(0, 20px, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
    -webkit-transform: translate3d(0, 0, 0);
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
    transform: translate3d(0, 0, 0);
    -webkit-transform: translate3d(0, 0, 0);
  }
  to {
    opacity: 0;
    transform: translate3d(0, -20px, 0);
    -webkit-transform: translate3d(0, -20px, 0);
  }
}

.prosess-feature-image {
  position: relative;
  height: 560px;
}

.prosess-feature-image .img-one, .prosess-feature-image .img-two {
  position: absolute;
}

.prosess-feature-image .img-one {
  right: -200px;
  top: -45px;
}

.prosess-feature-image .img-two {
  left: 0;
  top: -26px;
}

.tab-box {
  width: 100%;
}

.tab-box .buttons-wrap {
  width: 100%;
  position: relative;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
}

.tab-box .buttons {
  width: 100%;
  white-space: nowrap;
  overflow: auto;
  position: relative;
  margin: 0;
  padding: 0;
  list-style: none;
}

.tab-box .buttons:after {
  content: '';
  display: table;
  clear: both;
}

.tab-box .buttons .line {
  background-color: #3224af;
  width: 0px;
  height: 3px;
  position: absolute;
  bottom: 0px;
  left: 0;
  -webkit-transition: 0.3s cubic-bezier(0.2, 0.35, 0.25, 1);
  -o-transition: 0.3s cubic-bezier(0.2, 0.35, 0.25, 1);
  transition: 0.3s cubic-bezier(0.2, 0.35, 0.25, 1);
  -webkit-transition-property: width, -webkit-transform;
  transition-property: width, -webkit-transform;
  -o-transition-property: width, transform;
  transition-property: width, transform;
  transition-property: width, transform, -webkit-transform;
}

.tab-box .button {
  width: auto;
  margin: 0 30px 0 0;
  padding: 10px 0;
  font-size: 14px;
  font-weight: 500;
  display: inline-block;
  color: #6b727f;
  clear: none;
  cursor: pointer;
  -webkit-transition: color .15s ease-in-out;
  -o-transition: color .15s ease-in-out;
  transition: color .15s ease-in-out;
}

.tab-box .button.active, .tab-box .button:hover {
  color: #3224af;
}

.tab-box .items {
  position: relative;
  -webkit-transition: height 0.3s cubic-bezier(0.2, 0.35, 0.25, 1);
  -o-transition: height 0.3s cubic-bezier(0.2, 0.35, 0.25, 1);
  transition: height 0.3s cubic-bezier(0.2, 0.35, 0.25, 1);
}

.tab-box .item {
  width: 100%;
  opacity: 0;
  overflow: hidden;
  -webkit-transition: 0.2s cubic-bezier(0.2, 0.35, 0.25, 1);
  -o-transition: 0.2s cubic-bezier(0.2, 0.35, 0.25, 1);
  transition: 0.2s cubic-bezier(0.2, 0.35, 0.25, 1);
  -webkit-transition-property: opacity, visibility;
  -o-transition-property: opacity, visibility;
  transition-property: opacity, visibility;
  position: absolute;
  top: 15px;
  left: 0;
  font-size: 15px;
  font-weight: 400;
}

.tab-box .item.active {
  opacity: 1;
  overflow: visible;
  z-index: 2;
  animation: fadeIn .75s ease forwards;
}

.tab-box .item p {
  margin-bottom: 20px;
}

.tab-box .item .read-btn {
  padding: 10px 20px;
  background: #3224af;
  color: #fff;
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 500;
  border-radius: 30px;
  display: inline-block;
  border: 1px solid #3224af;
}

.tab-box .item .read-btn i {
  margin-left: 3px;
  transition: all 0.3s ease-in-out;
}

.tab-box .item .read-btn:hover {
  background: transparent;
  border-color: #3224af;
  color: #3224af;
}

.tab-box .item .read-btn:hover i {
  margin-left: 7px;
}

.hotspot-wraper {
  position: absolute;
  top: 210px;
  z-index: 9999;
  left: 85px;
  cursor: pointer;
}

.hotspot-wraper .hotspot-hover {
  background: #fff;
  padding: 20px 15px;
  text-align: center;
  width: 220px;
  z-index: 99999;
  position: absolute;
  left: -100px;
  top: -100px;
  box-shadow: 0px 30px 50px 0px rgba(1, 0, 57, 0.16);
  opacity: 0;
  visibility: hidden;
  transform: scale(0.8);
  transition: all 0.3s ease-in-out;
}

.hotspot-wraper .hotspot-hover:after {
  content: '';
  position: absolute;
  bottom: -10px;
  left: 48%;
  transform: translateX(-50%);
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid #fff;
}

.hotspot-wraper .hotspot-hover p {
  margin-bottom: 0;
}

.hotspot-wraper:hover .hotspot-hover {
  opacity: 1;
  visibility: visible;
  transform: scale(1);
}

.pulse-box {
  width: 60px;
}

.pulse-box svg {
  width: 10px;
}

/* pulse in SVG */
svg.pulse-svg {
  overflow: visible;
}

svg.pulse-svg .first-circle, svg.pulse-svg .second-circle, svg.pulse-svg .third-circle {
  fill: #3224af;
  transform: scale(0.5);
  transform-origin: center center;
  animation: pulse-me 3s linear infinite;
}

svg.pulse-svg .second-circle {
  animation-delay: 1s;
}

svg.pulse-svg .third-circle {
  animation-delay: 2s;
}

@keyframes pulse-me {
  0% {
    transform: scale(0.5);
    opacity: 0;
  }
  50% {
    opacity: 0.2;
  }
  70% {
    opacity: 0.09;
  }
  100% {
    transform: scale(5);
    opacity: 0;
  }
}

@media (max-width: 1200px) {
  .prosess-feature-image .img-two {
    top: 50px;
  }
  .hotspot-wraper {
    left: 150px;
    top: 250px;
  }
}

@media (max-width: 991px) {
  .prosess-tabs-inner {
    margin-top: 0;
    margin-bottom: 100px;
  }
  .prosess-feature-image {
    height: 550px;
  }
  .prosess-feature-image .img-two {
    top: -43px;
  }
  .hotspot-wraper {
    left: 41px;
    top: 198px;
  }
}

@media (max-width: 767px) {
  .prosess-feature-image .img-one {
    right: -145px;
    top: -50px;
  }
  .prosess-feature-image .img-one img {
    max-width: 80%;
  }
  .hotspot-wraper {
    left: 215px;
    top: 158px;
  }
  #prosess {
    padding: 50px 0;
  }
  .prosess-feature-image {
    height: 430px;
  }
}

@media (max-width: 576px) {
  .prosess-feature-image {
    height: 450px;
  }
  .hotspot-wraper {
    display: none;
  }
  .prosess-feature-image .img-one {
    top: 0;
  }
  .prosess-feature-image .img-two {
    top: 30px;
  }
  .prosess-tabs-inner .title {
    font-size: 24px;
    margin-bottom: 15px;
  }
}

/*--------------------------------------------------------------
  ##  Countup
  --------------------------------------------------------------*/
#countup {
  background: #f9fbff;
  padding: 100px 0 70px;
}

.count-thumb {
  position: absolute;
  left: 0;
  top: -220px;
}

.counter-box {
  background: #fff;
  box-shadow: 0px 3px 4px 0px rgba(1, 0, 57, 0.06);
  text-align: center;
  margin-bottom: 30px;
  padding: 35px 0;
  transition: all 0.3s ease-in-out;
}

.counter-box .count i {
  font-size: 40px;
  color: #6b727f;
  margin-bottom: 25px;
  display: inline-block;
  transition: all 0.3s ease-in-out;
}

.counter-box .count h2 {
  font-size: 36px;
  font-weight: 800;
  color: #6b727f;
  transition: all 0.3s ease-in-out;
}

.counter-box .count p {
  font-size: 20px;
  color: #a9aeb8;
  margin: 0;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
}

.counter-box:hover {
  box-shadow: 0px 10px 20px 0px rgba(1, 0, 57, 0.06);
}

.counter-box:hover .count i {
  color: #3224af;
}

.counter-box:hover .count h2 {
  color: #3224af;
}

@media (max-width: 992px) {
  .count-thumb {
    position: relative;
    top: 0;
    text-align: center;
    max-width: 400px;
    margin: 0 auto 20px;
  }
}

@media (max-width: 768px) {
  #countup {
    padding: 50px 0 20px;
  }
}

/*--------------------------------------------------------------
  ##  Contact
  --------------------------------------------------------------*/
#contact {
  background: #f9fbff;
  padding: 100px 0;
}

.width-half {
  width: 50%;
  float: left;
  padding: 0 15px;
}

#appxbe-contact-form input {
  width: 100%;
  height: 60px;
  background: #fff;
  box-shadow: 0px 2px 4px 0px rgba(1, 0, 57, 0.08);
  border-radius: 30px;
  border: 0;
  padding: 20px 30px;
  outline: 0;
  margin-bottom: 20px;
}

#appxbe-contact-form textarea {
  width: 100%;
  height: 150px;
  background: #fff;
  box-shadow: 0px 2px 4px 0px rgba(1, 0, 57, 0.08);
  border-radius: 30px;
  outline: 0;
  padding: 20px;
  border: 0;
  margin-bottom: 10px;
}

#contact-submit {
  display: block;
  border: 0;
  background: transparent;
  background-image: linear-gradient(40deg, #4745e4 0%, #18cff5 100%);
  width: 100%;
  padding: 15px;
  text-align: center;
  color: #fff;
  border-radius: 30px;
  letter-spacing: 6px;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 500;
  margin-top: 20px;
  outline: 0;
  cursor: pointer;
  border: 1px solid transparent;
  transition: all 0.3s ease-in-out;
}

#contact-submit:hover {
  background-image: none;
  border: 1px solid #3224af;
  color: #3224af;
}

.gmap3-area {
  height: 410px;
  border: 5px solid #fff;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
}

@media (max-width: 992px) {
  #contact-submit {
    margin-bottom: 30px;
  }
}

@media (max-width: 768px) {
  #contact {
    padding: 50px 0;
  }
}

/*--------------------------------------------------------------
  ##  Widgets
  --------------------------------------------------------------*/
/*
*
* Widget Search
*
*/
.widget {
  margin-bottom: 70px;
}

.widget .widget-title {
  font-size: 20px;
  font-weight: 600;
  color: #282835;
  margin-bottom: 30px;
}

.widget_search form {
  border: 1px solid #e0e3ec;
  border-radius: 3px;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  padding: 3px;
}

.widget_search form label {
  width: 91%;
  margin: 0;
}

.widget_search form .search-field {
  border: 0;
  background-color: transparent;
  width: 100%;
  padding: 10px;
  height: 50px;
  outline: 0;
  font-size: 16px;
  font-weight: 500;
}

.widget_search form .search-field::placeholder {
  color: #999999;
}

.widget_search form .search-submit {
  background: #3224af;
  padding: 10px 24px;
  border: 0;
  color: #fff;
  border-bottom-left-radius: 30px;
  border-top-left-radius: 30px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.widget_search form .search-submit:hover {
  background: #444;
}

/*
*
* Recent Post
*
*/
.gp-posts-widget .post-item {
  margin-bottom: 30px;
  display: flex;
}

.gp-posts-widget .post-widget-thumbnail {
  flex-shrink: 0;
  margin-right: 20px;
  width: 80px;
  height: 80px;
  position: relative;
}

.gp-posts-widget .post-widget-thumbnail:hover .post-widget-overlay {
  visibility: visible;
  opacity: 1;
}

.gp-posts-widget .post-widget-thumbnail img {
  width: 100%;
}

.gp-posts-widget .post-widget-info {
  flex-grow: 1;
}

.gp-posts-widget .post-widget-info span {
  color: #656971;
  font-size: 14px;
  font-family: "Poppins", sans-serif;
  font-weight: 300;
}

.gp-posts-widget .post-widget-info .commemnts {
  margin-left: 20px;
  position: relative;
}

.gp-posts-widget .post-widget-info .commemnts:before {
  content: '';
  position: absolute;
  top: 4px;
  left: -13px;
  height: 12px;
  width: 1px;
  background: #656971;
}

.gp-posts-widget .post-widget-title {
  margin-bottom: 5px;
  line-height: 28px;
  font-weight: 500;
  font-size: 16px;
}

.gp-posts-widget .post-widget-title a {
  color: #43464c;
}

.gp-posts-widget .post-widget-title a:hover {
  color: #3224af;
  text-decoration: underline;
}

.gp-posts-widget .post-date {
  font-size: 13px;
}

.gp-posts-widget .post-widget-overlay {
  transition: all 0.3s ease-in-out;
  position: absolute;
  top: 0;
  left: 0;
  visibility: hidden;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  opacity: 0;
}

.gp-posts-widget .post-overlay-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #ffffff;
  font-weight: bold;
}

/*
*
* Categories
*
*/
.widget ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.widget ul li a {
  font-size: 16px;
  font-weight: 400;
  color: #5d6068;
  line-height: 40px;
  position: relative;
  display: block;
  font-family: "Poppins", sans-serif;
}

.widget ul li a .count {
  position: absolute;
  right: 0;
  top: 0;
}

.widget ul li a:hover {
  color: #3224af;
}

/*
*
* Tags
*
*/
.tagcloud a {
  display: inline-block;
  padding: 4px 17px;
  border: 1px solid #cacfdc;
  border-radius: 30px;
  color: #5d6068;
  margin-right: 3px;
  margin-bottom: 5px;
  font-size: 14px;
  font-family: "Poppins", sans-serif;
}

.tagcloud a:hover {
  background: #3224af;
  color: #fff;
  border-color: #3224af;
}

/*--------------------------------------------------------------
  ##  Page Header
  --------------------------------------------------------------*/
#page-header {
  height: 500px;
  position: relative;
  background-image: url(../../media/banner/1.jpg);
}

.page-header-inner {
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  text-align: center;
  transform: translateY(-50%);
}

.page-header-inner .page-title h2 {
  font-size: 60px;
  font-weight: 800;
  color: #fff;
}

.page-header-inner .breadcrumbs {
  margin: 0;
  padding: 0;
  list-style: none;
}

.page-header-inner .breadcrumbs li {
  display: inline-block;
  margin-right: 30px;
  position: relative;
  font-size: 14px;
  color: #bfbfbf;
  font-weight: 600;
  letter-spacing: 1px;
}

.page-header-inner .breadcrumbs li:after {
  content: '/';
  position: absolute;
  right: -20px;
  top: 1px;
  color: #fff;
  font-size: 16px;
}

.page-header-inner .breadcrumbs li a {
  color: #fff;
  font-size: 14px;
}

.page-header-inner .breadcrumbs li a:hover {
  color: #c5c5c5;
}

.page-header-inner .breadcrumbs li:last-child:after {
  display: none;
}

/*--------------------------------------------------------------
  ##  Footer
  --------------------------------------------------------------*/
#site-footer {
  background: #23273b;
  padding-top: 120px;
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: -1;
}

.site-footer .widget-about .footer-logo {
  margin-bottom: 20px;
}

.site-footer .widget-about .content {
  color: #aab0ca;
  font-size: 14px;
  margin-bottom: 30px;
}

.site-footer .widget {
  margin-bottom: 0;
}

.site-footer .widget .widget-title {
  font-size: 20px;
  margin-bottom: 15px;
  color: #fff;
  font-weight: 600;
}

.site-footer .widget .footer-menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

.site-footer .widget .footer-menu li {
  line-height: 30px;
}

.site-footer .widget .footer-menu li a {
  color: #aab0ca;
  font-size: 12px;
  text-transform: uppercase;
  position: relative;
  font-family: "Poppins", sans-serif;
  display: inline-block;
}

.site-footer .widget .footer-menu li a:after {
  position: absolute;
  content: '';
  display: block;
  bottom: 5px;
  height: 1px;
  left: 0;
  width: 100%;
  background: #fff;
  transform-origin: right center;
  transform: scale(0, 1);
  transition: transform 0.25s cubic-bezier(0.37, 0.31, 0.2, 0.85);
}

.site-footer .widget .footer-menu li a:hover {
  color: #fff;
}

.site-footer .widget .footer-menu li a:hover:after {
  transform-origin: left center;
  transform: scale(1, 1);
}

.site-footer .footer-inner-two .widget .widget-title {
  color: #3b566e;
}

.site-footer .footer-inner-two .widget .footer-menu li a {
  color: #8b8b99;
}

.site-footer .footer-inner-two .widget .footer-menu li a:after {
  background: #3224af;
}

.site-footer .footer-inner-two .widget .footer-menu li a:hover {
  color: #3224af;
}

.footer-inner {
  padding-bottom: 70px;
}

.footer-social-link {
  padding: 0;
  list-style: none;
  margin: 0;
}

.footer-social-link li {
  display: inline-block;
  margin: 0.15em;
  position: relative;
  font-size: 1.2em;
}

.footer-social-link i {
  color: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  transition: all 265ms ease-out;
  transform: translate(-50%, -50%);
}

.footer-social-link a {
  display: inline-block;
  position: relative;
}

.footer-social-link a:before {
  transform: scale(1);
  -ms-transform: scale(1);
  -webkit-transform: scale(1);
  content: " ";
  width: 35px;
  height: 35px;
  border-radius: 100%;
  display: block;
  background: linear-gradient(45deg, #ff003c, #c648c8);
  transition: all 600ms ease-out;
}

.footer-social-link a:hover:before {
  transform: rotate(-180deg);
  -ms-transform: rotate(-180deg);
  -webkit-transform: scale(-180deg);
  border-radius: 100%;
}

.footer-social-link a:before {
  background: linear-gradient(45deg, #7b00e0, #ae31d9);
  border-radius: 10%;
}

.site-info {
  padding: 16px 0;
  border-top: 1px solid #363b50;
  text-align: center;
}

.site-info .copyright {
  margin: 0;
  font-size: 12px;
  color: #858aa5;
}

.site-info .copyright a {
  color: #fff;
  font-weight: 500;
}

.site-info .copyright a:hover {
  color: #b9b9b9;
}

/* Footer Two */
#site-footer-two {
  background: #f3f3fc;
  position: relative;
  overflow: hidden;
}

#site-footer-two .widget-about p {
  color: #8b8b99;
}

#site-footer-two .site-info {
  border-color: #e3e3f2;
}

#site-footer-two .site-info .copyright {
  text-align: left;
}

#site-footer-two .site-info .copyright a {
  color: #3224af;
}

.footer-inner-two {
  padding: 100px 0 50px;
  position: relative;
}

.footer-contact-info {
  margin: 0;
  padding: 0;
  list-style: none;
}

.footer-contact-info li {
  line-height: 35px;
  font-size: 15px;
  font-family: "Poppins", sans-serif;
}

.footer-contact-info li span {
  font-weight: 600;
  color: #466077;
  font-size: 16px;
}

.follow-us {
  display: flex;
  margin-top: 10px;
}

.follow-us span {
  margin-right: 10px;
  color: #466077;
  font-weight: 16px;
  font-weight: 600;
}

.footer-social-two {
  margin: 0;
  padding: 0;
  list-style: none;
}

.footer-social-two li {
  display: inline-block;
  margin-right: 10px;
}

.footer-social-two li a {
  display: inline-block;
  color: #8b8b99;
}

.footer-social-two li a:hover {
  color: #3224af;
}

.footer-menu-bottom {
  margin: 0;
  padding: 0;
  list-style: none;
  text-align: right;
}

.footer-menu-bottom li {
  display: inline-block;
  margin-right: 40px;
}

.footer-menu-bottom li:last-child {
  margin-right: 0;
}

.footer-menu-bottom li a {
  color: #8b8b99;
  font-size: 12px;
  text-transform: uppercase;
}

.footer-menu-bottom li a:hover {
  color: #3224af;
}

.bubble1 {
  top: -85px;
  left: -120px;
  position: absolute;
}

.bubble2 {
  top: 10%;
  left: -170px;
  position: absolute;
}

.bubble3 {
  bottom: -16%;
  right: -275px;
  position: absolute;
}

.bubble4 {
  bottom: -54%;
  right: -185px;
  position: absolute;
}

@media (max-width: 991px) {
  .widget {
    margin-bottom: 30px;
  }
}

@media (max-width: 768px) {
  #site-footer {
    position: static;
    padding-top: 50px;
  }
  .footer-inner {
    padding-bottom: 20px;
  }
  #site-footer-two .site-info .copyright {
    text-align: center;
  }
  .footer-menu-bottom {
    text-align: center;
  }
  .footer-menu-bottom li {
    margin-right: 20px;
    font-size: 11px;
  }
}


body {
	font-family: $font_primary;
	color: #8b8b99;
	font-size: 15px;
	line-height: 26px;
	overflow-x: hidden;
}

.starter-template {
	padding: 40px 15px;
	text-align: center;
}


svg {
	display: block;
}

a {
	transition: all 0.15s ease-in-out;
}

a,
a:hover {
	text-decoration: none;
}

img {
	max-width: 100%;
	height: auto;
}

h1, h2, h3, h4, h5, h6 {
	font-family: $font_heading;
	color: $color__heading;
	margin-top: 0;
	font-weight: 600;
}

section {
	position: relative;
}

#site {
	background:	#fff;
}

@media (max-width: 768px) {
	#site {
		margin-bottom: 0 !important;
	}

	#site-footer {
		position: static;
	}
}

.gp-btn {
	display: inline-block;
	background-image: $button__bg;
	background-size: 200% auto;
	color: #fff;
	padding: 14px 23px;
	font-size: 14px;
	font-weight: 600;
	text-transform: uppercase;
	transition: all 0.3s ease-in-out;
	//background-size: 100% auto;
	transition: 0.5s;
	border-radius: 2px;


	&:hover {
		background-position: right center;
		color: #fff;
	}

}


.section-title {
	text-align: center;

	h2 {
		font-size: 30px;
		line-height: 30px;
		color: $color__heading;
		padding-bottom: 10px;
		margin-bottom: 50px;
		position: relative;
		display: inline-block;
		font-weight: 700;

		&:before, &:after {
			position: absolute;
			content: '';
			width: 70px;
			height: 1px;
			background-image: $color_gradient;
			left: 50%;
			transform: translateX(-50%)

		}

		&:before {
			bottom: 0;
			left: 45%;
		}

		&:after  {
			bottom: -5px;
			left: 55%;
		}
	}
}



/*
Flaticon icon font: Flaticon
Creation date: 04/06/2018 14:38
*/

@font-face {
	font-family: "Flaticon";
	src: url("./Flaticon.eot");
	src: url("../fonts/Flaticon.eot?#iefix") format("embedded-opentype"),
	url("../fonts/Flaticon.woff") format("woff"),
	url("../fonts/Flaticon.ttf") format("truetype"),
	url("../fonts/Flaticon.svg#Flaticon") format("svg");
	font-weight: normal;
	font-style: normal;
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
	@font-face {
		font-family: "Flaticon";
		src: url("../fonts/Flaticon.svg#Flaticon") format("svg");
	}
}

.fi:before{
	display: inline-block;
	font-family: "Flaticon";
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	line-height: 1;
	text-decoration: inherit;
	text-rendering: optimizeLegibility;
	text-transform: none;
	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased;
	font-smoothing: antialiased;
}

.flaticon-apple-2:before { content: "\f100"; }
.flaticon-google-play-1:before { content: "\f101"; }
.flaticon-apple-1:before { content: "\f102"; }
.flaticon-apple:before { content: "\f103"; }
.flaticon-google-play:before { content: "\f104"; }

$font-Flaticon-apple-2: "\f100";
$font-Flaticon-google-play-1: "\f101";
$font-Flaticon-apple-1: "\f102";
$font-Flaticon-apple: "\f103";
$font-Flaticon-google-play: "\f104";


@media (max-width: 576px) {
	.section-title {
		h2 {
			font-size: 24px;
		}
	}
}
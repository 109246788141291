#page-header {
	height: 500px;
	position: relative;
	background-image: url(../../media/banner/1.jpg);
}

.page-header-inner {
	position: absolute;
	left: 0;
	top: 50%;
	width: 100%;
	text-align: center;
	transform: translateY(-50%);

	.page-title {
		h2 {
			font-size: 60px;
			font-weight: 800;
			color: #fff;
		}
	}

	.breadcrumbs {
		margin: 0;
		padding: 0;
		list-style: none;

		li {
			display: inline-block;
			margin-right: 30px;
			position: relative;
			font-size: 14px;
			color: #bfbfbf;
			font-weight: 600;
			letter-spacing: 1px;

			&:after {
				content: '/';
				position: absolute;
				right: -20px;
				top: 1px;
				color: #fff;
				font-size: 16px;
			}

			a {
				color: #fff;
				font-size: 14px;

				&:hover {
					color: #c5c5c5;
				}
			}

			&:last-child {
				&:after {
					display: none;
				}
			}
		}
	}
}
/*!
  Theme Name: Appxbe
  Theme URI: http://html.gptheme.co/appxbe
  Author: GPTheme
  Author URI: http://www.gptheme.co/
  Description: App Landing HTML Template
  Version: 1.0.0
  License: GNU General Public License v2 or later
  License URI: http://www.gnu.org/licenses/gpl-2.0.html
  Text Domain: appxbe
  Tags: game,business,coporate,startup
  */
/*--------------------------------------------------------------
  >>> TABLE OF CONTENTS:
  ----------------------------------------------------------------
  # Base
  # Sections
    ## Header
    ## Banner
    ## Showcase
    ## Feature
    ## Screenshort
    ## Faq
    ## Pricing-table
    ## Download
    ## Testimonial
    ## News
    ## Team
    ## News-letter
    ## Prosess
    ## Countup
    ## Contact
    ## Contact
    ## Footer

  --------------------------------------------------------------*/
@import "base/variables";
@import "base/mixins";
@import "base/base";
@import "elements/elements";


/*
*
* Widget Search
*
*/

.widget {
	margin-bottom: 70px;

	.widget-title {
		font-size: 20px;
		font-weight: 600;
		color: #282835;
		margin-bottom: 30px;
	}
}


.widget_search form {
	border: 1px solid #e0e3ec;
	border-radius: 3px;
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
	padding: 3px;


	label {
		width: 91%;
		margin: 0;
	}

	.search-field {
		border: 0;
		background-color: transparent;
		width: 100%;
		padding: 10px;
		height: 50px;
		outline: 0;
		font-size: 16px;
		font-weight: 500;

		&::placeholder {
			color: #999999;
		}
	}

	.search-submit {
		background: $color__theme;
		padding: 10px 24px;
		border: 0;
		color: #fff;
		border-bottom-left-radius: 30px;
		border-top-left-radius: 30px;
		cursor: pointer;
		transition: all 0.3s ease-in-out;

		&:hover {
			background: #444;
		}
	}
}


/*
*
* Recent Post
*
*/


.gp-posts-widget {
	.post-item {
		margin-bottom: 30px;
		display: flex;

	}
	.post-widget-thumbnail {
		flex-shrink: 0;
		margin-right: 20px;
		width: 80px;
		height: 80px;
		position: relative;
		&:hover {
			.post-widget-overlay {
				visibility: visible;
				opacity: 1;
			}
		}
		img {
			width: 100%;
		}
	}
	.post-widget-info {
		flex-grow: 1;

		span {
			color: #656971;
			font-size: 14px;
			font-family: $font_heading;
			font-weight: 300;
		}

		.commemnts {
			margin-left: 20px;
			position: relative;

			&:before {
				content: '';
				position: absolute;
				top: 4px;
				left: -13px;
				height: 12px;
				width: 1px;
				background: #656971;
			}
		}
	}
	.post-widget-title {
		margin-bottom: 5px;
		line-height: 28px;
		font-weight: 500;
		font-size: 16px;

		a {
			color: #43464c;

			&:hover {
				color: $color__theme;
				text-decoration: underline;
			}
		}



	}
	.post-date {
		font-size: 13px;
	}
	.post-widget-overlay {
		transition: all 0.3s ease-in-out;
		position: absolute;
		top: 0;
		left: 0;
		visibility: hidden;
		width: 100%;
		height: 100%;
		background-color: rgba(0, 0, 0, .6);
		opacity: 0;
	}
	.post-overlay-icon {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		color: #ffffff;
		font-weight: bold;
	}
}

/*
*
* Categories
*
*/

.widget {
	ul {
		margin: 0;
		padding: 0;
		list-style: none;

		li {
			a {
				font-size: 16px;
				font-weight: 400;
				color: #5d6068;
				line-height: 40px;
				position: relative;
				display: block;
				font-family: $font_heading;

				.count {
					position: absolute;
					right: 0;
					top: 0;
				}

				&:hover {
					color: $color__theme;
				}
			}
		}
	}
}

/*
*
* Tags
*
*/

.tagcloud {
	a {
		display: inline-block;
		padding: 4px 17px;
		border: 1px solid #cacfdc;
		border-radius: 30px;
		color: #5d6068;
		margin-right: 3px;
		margin-bottom: 5px;
		font-size: 14px;
		font-family: $font_heading;

		&:hover {
			background: $color__theme;
			color: #fff;
			border-color: $color__theme;
		}
	}
}
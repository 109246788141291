
.header-inner {
	.site-logo {
		float: left;
		padding: 24px 0;

		.main-logo {
			display: block;
		}

		.sticky-logo {
			display: none;
		}
	}
}

.site-nav {
	float: right;
	display: flex;

	.site-menu {
		margin: 0;
		list-style: none;
		position: relative;
		width: 600px;
		text-align: right;

		li {
			display: inline-block;
			margin-right: 30px;
			padding: 31px 0;

			a {
				color: #fff;
				text-transform: uppercase;
				font-weight: 500;
				font-size: 12px;
			}

			&.menu-item-has-children {
				position: relative;
				.sub-menu {
					visibility: hidden;
					opacity: 0;
					margin: 0;
					padding: 0;
					list-style: none;
					background: #fff;
					position: absolute;
					top: 110%;
					left: 0;
					width: 200px;
					text-align: left;
					padding: 20px;
					transition: all 0.3s ease-in-out;

					li {
						display: block;
						padding: 0;

						a {
							display: block;
							line-height: 30px;
							color: #000;

							&:hover {
								color: $color__theme;
							}
						}
					}
				}

				&:hover {
					.sub-menu {
						visibility: visible;
						opacity: 1;
						top: 100%;
					}
				}
			}


		}
	}

	.nav-button {
		margin: 20px 0 25px 20px;
		background: #fff;
		color: $color__theme;
		box-shadow: 0px 20px 30px 0px rgba(0, 0, 29, 0.14);
		padding: 9px 15px;
		border-radius: 2px;
		text-transform: uppercase;
		font-weight: 600;
		font-size: 14px;
		height: 44px;

		&:hover {
			background-image: $color_gradient;
			color: #fff;
		}

		&.nbtn-two {
			background: $color__theme;
			border-radius: 30px;
			box-shadow: none;
			color: #fff;
			font-weight: 600;
			font-size: 12px;
			padding: 9px 28px;
			border: 1px solid transparent;

			&:hover {
				background: #fff;
				color: $color__theme;
			}
		}

	}
}

.header-two {
	.site-nav {
		.site-menu {
			width: 700px;
			li {
				a {
					position: relative;

					&:before, &:after {
						content: '';
						position: absolute;
						bottom: -5px;
						left: 0;
						width: 100%;
						height: 1px;
						transform: scale3d(0, 1, 1);
						background: #fff;
						transition: transform 0.4s cubic-bezier(0.22, 0.61, 0.36, 1);
					}

					&:hover, &.menu__item--current {
						&:before {
							transform: scale3d(1, 1, 1);
							transition-delay: 0s;
						}
					}

					 				}
			}
		}
	}
}


#magic-line {
	position: absolute;
	bottom: 32px;
	left: 0;
	width: 100px;
	height: 2px;
	background: #fff;
}


.gp-hidden-menu {
	top: -90px !important;
	animation-name: headerSlideDown;
	transition: all 0.3s ease-in-out;
}

.header-main {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	z-index: 99999;
}


.mob-menu-open {
	position: relative;
	float: right;
	display: block;
	padding: 13px 0;
	text-align: right;
	display: none;

	.bar {
		background: #fff;
		height: 2px;
		width: 30px;
		display: block;
		margin-bottom: 5px;
		transition: all 0.3s ease-in-out;

		&:nth-child(2), &:nth-child(3) {
			width: 25px;
			background: #d286de;
			margin-left: 5px;
		}

		&:last-child {
			margin-bottom: 0;
		}
	}

	&.active {
		.bar {
			&:nth-child(2), &:nth-child(3) {
				opacity: 0;
			}

			&:nth-child(1) {
				transform: rotate(45deg) translate(7px, 7px);
			}

			&:nth-child(4) {
				transform: rotate(-45deg) translate(8px, -8px);
			}
		}
	}
}


.gp-header-fixed {
	top: 0;
	left: 0;
	right: 0;
	position: fixed;
	z-index: 999999;
	box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.1);
	transition: all 0.3s ease-in-out;
	background: #fff;

	.site-logo {
		padding: 11px 0;
	}

	.site-menu {

		li {
			padding: 18px 0;
			a {
				color: $color__heading;

				&:hover {
					color: $color__theme;

					&:before, &:after {
						background: $color__theme;
					}
				}
			}

			&.menu__item--current {
				a {
					color: $color__theme;

					&:before, &:after {
						background: $color__theme;
					}
				}
			}
		}

	}

	.nav-button {
		border: 1px solid $color__theme;
		background-size: 200% auto;
		color: $color__theme;
		margin: 10px 0 10px 20px;
		box-shadow: none;

		&:hover {
			background-image: $button__bg;
			color: #fff;
			border-color: transparent;
		}

		&.nbtn-two {

			&:hover {
				background: transparent;
				color: $color__theme;
				border-color: $color__theme;
			}
		}
	}

	.site-logo {
		.main-logo {
			display: none;
		}

		.sticky-logo {
			display: block;
		}
	}

	#magic-line {
		background: $color__theme;
		bottom: 20px;
	}

	.mob-menu-open {

		.bar {
			background: $color__heading;

			&:nth-child(2), &:nth-child(3) {
				background: $color__theme;

			}
		}
	}
}

.gp-hidden-menu {
	transition: all 0.3s ease-in-out;
}

.header-two {
	.header-main {
		border-bottom: 1px solid rgba(255, 255, 255, 0.102);
	}
}


/* Page Loader */
.page-loader {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: $color_gradient;
	z-index: 99999999;
}

@-webkit-keyframes span {
	0%{
		opacity: 0;
		-webkit-transform: scale(.6);
		transform: scale(.6);
	}
	60% {
		opacity: 1;
	}
	90% {
		opacity: 1;
	}
	100% {
		opacity: 0;
		-webkit-transform: scale(1);
		transform: scale(1);
	}
}
@keyframes span {
	0%{
		opacity: 0;
		-webkit-transform: scale(.6);
		transform: scale(.6);
	}
	60% {
		opacity: 1;
	}
	90% {
		opacity: 1;
	}
	100% {
		opacity: 0;
		-webkit-transform: scale(1);
		transform: scale(1);
	}
}
@-webkit-keyframes line {
	0%{
		width: 25vmin;
		opacity: 0;
	}
	10%{
		opacity: 1;
	}
	100% {
		width: 350vmin;
	}
}
@keyframes line {
	0%{
		width: 25vmin;
		opacity: 0;
	}
	10%{
		opacity: 1;
	}
	100% {
		width: 350vmin;
	}
}
@-webkit-keyframes inner {
	from {
		width: 20%;
	}
	to {
		width: 0%;
	}
}
@keyframes inner {
	from {
		width: 10%;
		-webkit-transform: rotate(-360deg);
		transform: rotate(-360deg);
	}
	to {
		width: 0%;
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}
#preloader {
	color: white;
	position: absolute;
	text-align: center;
	top: 50%;
	left: 50%;
}
#preloader span{
	font-size: 20px;

	position: absolute;
	top: 50%;
	left: 50%;
	margin-left: -30px;
	transform: translate(-50%, -50%);
	width: 50px;
	-webkit-animation: span 1.333s linear infinite;
	animation: span 1.333s linear infinite;
}
.spinner {
	position: absolute;
	top: 50%;
	left: 50%;
}
.spinner .line {
	position: absolute;
	height: 1px;
	-webkit-animation: line 8s linear infinite;
	animation: line 8s linear infinite;
}
.spinner .line::before, .spinner .line::after {
	content: '';
	position: absolute;
	display: block;
	top: 0;
	right: 0;
	width: 100%;
	height: 100%;
	background: currentColor;
	-webkit-transform-origin: right;
	-ms-transform-origin: right;
	transform-origin: right;
	-webkit-animation: inner 8s linear infinite;
	animation: inner 8s linear infinite;
}
.spinner .line::before {
	-webkit-transform: translateX(-0.5em) rotate(30deg) translateX(0.1em);
	-ms-transform: translateX(-0.5em) rotate(30deg) translateX(0.1em);
	transform: translateX(-0.5em) rotate(30deg) translateX(0.1em);
}
.spinner .line::after {
	-webkit-transform: translateX(-0.5em) rotate(-30deg) translateX(0.1em);
	-ms-transform: translateX(-0.5em) rotate(-30deg) translateX(0.1em);
	transform: translateX(-0.5em) rotate(-30deg) translateX(0.1em);
}
.spinner .line:nth-child(1) {
	-webkit-transform: translate(-50%, -50%) rotate(60deg);
	-ms-transform: translate(-50%, -50%) rotate(60deg);
	transform: translate(-50%, -50%) rotate(60deg);
}
.spinner .line:nth-child(2) {
	-webkit-transform: translate(-50%, -50%) rotate(120deg);
	-ms-transform: translate(-50%, -50%) rotate(120deg);
	transform: translate(-50%, -50%) rotate(120deg);
}
.spinner .line:nth-child(3) {
	-webkit-transform: translate(-50%, -50%) rotate(180deg);
	-ms-transform: translate(-50%, -50%) rotate(180deg);
	transform: translate(-50%, -50%) rotate(180deg);
}
.spinner .line:nth-child(4) {
	-webkit-transform: translate(-50%, -50%) rotate(240deg);
	-ms-transform: translate(-50%, -50%) rotate(240deg);
	transform: translate(-50%, -50%) rotate(240deg);
}
.spinner .line:nth-child(5) {
	-webkit-transform: translate(-50%, -50%) rotate(300deg);
	-ms-transform: translate(-50%, -50%) rotate(300deg);
	transform: translate(-50%, -50%) rotate(300deg);
}
.spinner .line:nth-child(6) {
	-webkit-transform: translate(-50%, -50%) rotate(360deg);
	-ms-transform: translate(-50%, -50%) rotate(360deg);
	transform: translate(-50%, -50%) rotate(360deg);
}
.spinner:nth-child(1) .line,
.spinner:nth-child(1) .line::before,
.spinner:nth-child(1) .line::after {
	-webkit-animation-delay: 0s;
	animation-delay: 0s;
}
.spinner:nth-child(2) .line,
.spinner:nth-child(2) .line::before,
.spinner:nth-child(2) .line::after {
	-webkit-animation-delay: -1.33333s;
	animation-delay: -1.33333s;
}
.spinner:nth-child(3) .line,
.spinner:nth-child(3) .line::before,
.spinner:nth-child(3) .line::after {
	-webkit-animation-delay: -2.66666s;
	animation-delay: -2.66666s;
}
.spinner:nth-child(4) .line,
.spinner:nth-child(4) .line::before,
.spinner:nth-child(4) .line::after {
	-webkit-animation-delay: -4s;
	animation-delay: -4s;
}
.spinner:nth-child(5) .line,
.spinner:nth-child(5) .line::before,
.spinner:nth-child(5) .line::after {
	-webkit-animation-delay: -5.33333s;
	animation-delay: -5.33333s;
}
.spinner:nth-child(6) .line,
.spinner:nth-child(6) .line::before,
.spinner:nth-child(6) .line::after {
	-webkit-animation-delay: -6.66666s;
	animation-delay: -6.66666s;
}
.spinner:nth-child(2n) {
	-webkit-transform: rotate(30deg);
	-ms-transform: rotate(30deg);
	transform: rotate(30deg);
}


/* Mobile Menu */
.mobile-menu-inner {
	max-width: 300px;
	background: $color_gradient-two;
	position: fixed;
	width: 100%;
	height: 100vh;
	z-index: 99999;
	top: 50px;
	left: -100%;
	transition: all 0.5s ease-in-out;
	padding: 50px;
	display: none;

	.mobile-menu {
		margin: 0;
		list-style: none;
		text-align: center;
		padding: 0;

		li {
			line-height: 30px;

			a {
				color: #fff;
				font-size: 16px;
			}
		}
	}
}

.slide-menu {
	.mobile-menu-inner {
		left: 0;
	}
}

.mask-overlay {
	position: fixed;
	left: 0;
	top: 0;
	height: 100%;
	width: 100%;
	z-index: 111;
	// background: rgba(0,0,0,0.5);
}


@media (max-width: 991px) {
	.nav-button {
		display: none;
	}

	.mobile-menu-inner {
		display: block;
	}

	.header-inner {
		.site-logo {
			padding: 9px 0;
			max-width: 90px;
		}
	}

	#magic-line {
		display: none;
	}

	.site-nav{
		display: inherit;;
		width: 100%;


		.site-menu {
			clear: both;
			width: 100%;
			display: none;
			z-index: 99999;
			transition: max-height 0.2s ease-out;


			li {
				display: block;
				margin-right: 0;
				text-align: center;

				a {

					color: #b5b5b5;
					display: inline-block;
					text-align: center;
					text-decoration: none;
					padding: 0 20px;
					line-height: 40px;
					font-weight: 600;
					text-transform: uppercase;
					font-size: 14px;

					&:hover, &:focus {
						color: $color__theme;
					}
				}
			}
			.js & {
				li {
					a {
						max-height: 0;
						overflow: hidden;
						transition: max-height 0.2s ease-out;
					}
				}
				&.active {
					li {
						a {
							max-height: 70px;
						}
					}
				}
			}

			// &.active {
			// 	display: block;
			// 	transition: max-height 0.2s ease-out;
			// }
		}
	}

	.header-main {
		background: rgba(0,0,0,0.9);
		z-index: 9999;

		&.gp-header-fixed {
			background: #fff;
		}
	}

	.mob-menu-open {
		display: block;
	}

	.header-two {
		.site-nav {
			.site-menu {
				li {
					a {
						&:before, &:after {
							bottom: 0;
						}
					}
				}
			}
		}
	}
}




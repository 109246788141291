#download {
	background-image: linear-gradient( 45deg, rgb(201,109,216) 0%, rgb(50,36,175) 100%);
	padding: 150px 0;
	position: relative;
	overflow: hidden;
}

.store-btns {
	.store-btn {
		margin-right: 20px;
		transition: all 0.3s ease-in-out;
		display: inline-block;
		border: 1px solid #fff;
		color: #fff;
		padding: 7px 20px;
		font-size: 16px;
		border-radius: 3px;
		font-weight: 600;
		text-align: left;

		span {
			display: block;
			font-size: 12px;
			font-weight: 400;

		}

		.btn-text {
			margin-left: 40px;
			line-height: 21px;
		}

		i {
			float: left;
			font-size: 30px;
			line-height: 42px;
		}

		&:hover {
			background: #fff;
			border-color: #fff;
			color: #5c6777;

		}
	}
}

.download-content {
	margin-top: 30px;
	position: relative;
	z-index: 1;

	.download-title {
		font-size: 30px;
		font-weight: 700;
		color: #fff;
		margin-bottom: 20px;
	}

	p {
		color: #fff;
		margin-bottom: 50px;
	}
}

.wave-bg {


	.wave {
		position: absolute;
		left: 0;
		bottom: 0;
		height: 250px;
		z-index: 0;
	}
}

.download-thumb {
	position: relative;
	z-index: 1;

	.p1, .p2 {
		position: absolute;
	}

	.p1 {
		z-index: 2;
	}

	.p2 {
		right: -75px;
		top: 80px;
	}
}


/* Download Two */
#download-two {
	padding: 140px 0 220px;
	background: $color_gradient_two;
	position: relative;
	overflow: hidden;

	&:before {
		position: absolute;
		content: '';
		width: 100%;
		height: 100%;
		background-image: $color_gradient_two;
		opacity: 0.8;
		left: 0;
		top: 0;
		z-index: 2;
	}

	.download-content {
		margin-top: 0;
		position: relative;
		z-index: 22;
	}

	canvas {
		z-index: 333;
	}
}


.bg-animate-mob {
	img {
		position: absolute;
	}

	.mbg1 {
		top: -230px;
		left: -100px;
	}

	.mbg2 {
		left: 16%;
		top: -59%
	}

	.mbg3 {
		left: 2%;
		top: 40%;
	}

	.mbg4 {
		right: -14%;
		top: -25%;
	}

	.mbg5 {
		right: 12%;
		top: 10%;
	}


}


@media (max-width: 992px) {
	#download-two {
		padding: 100px 0 190px;
	}
}



@media (max-width: 768px) {

	#download {
		padding-top: 50px;
	}

	.download-content {
		margin-top: 0;
	}

	#download-two {
		padding: 80px 0 160px;
	}
}

@media (max-width: 576px) {
	.store-btns .store-btn {
		margin-bottom: 10px;
	}

	.card {
		.card-header {
			.btn {
				font-size: 14px;
			}
		}
	}

	.card-body p {
		font-size: 14px;
	}
}